export default function useEmail() {
  const resolveLabelColor = label => {
    if (label === 'Reseaux') return 'warning'
    if (label === 'Assureurs') return 'info'
    if (label === 'Clients') return 'secondary'
    if (label === 'Comptabilité') return 'danger'
    return ''
  }
  const resolveLabelText = label => {
    if (label === 'Reseaux') return 'Réseaux'
    if (label === 'Assureurs') return 'Assureurs'
    if (label === 'Clients') return 'Clients'
    if (label === 'Comptabilité') return 'Comptabilité'
    return ''
  }

  // const resolveTextEvenement = label => {
  //   if (label === 'Reseaux') return 'success'
  //   if (label === 'Assureurs') return 'primary'
  //   if (label === 'Clients') return 'warning'
  //   if (label === 'Inconnus') return 'danger'
  //   return 'secondary'
  // }

  return {
    resolveLabelColor,
    resolveLabelText
    // resolveTextEvenement,
  }
}
