<template>
  <b-card no-body>
    <b-card-header v-if="message.from" class="email-detail-head">
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar :src="message.from.avatar" class="mr-75" size="48"/>
        <div class="mail-items">
          <h5 class="mb-0">
            {{ message.from.name }}
          </h5>
          <b-dropdown class="email-info-dropup" no-caret toggle-class="p-0" variant="link">
            <template #button-content>
              <span class="font-small-3 text-muted mr-25">{{
                  message.to.map(receiver => receiver.email)
                      .join(', ')
                }}</span>
              <feather-icon icon="ChevronDownIcon" size="10"/>
            </template>
            <table class="table table-sm table-borderless font-small-3">
              <tbody>
              <tr>
                <td class="text-right text-muted align-top">De:</td>
                <td>{{ message.from.email }}</td>
              </tr>
              <tr>
                <td class="text-right text-muted align-top">À:</td>
                <td>{{
                    message.to.map(receiver => receiver.email)
                        .join(', ')
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-right text-muted align-top">Date:</td>
                <td>{{ formatDateTime(message.time) }}</td>
              </tr>
              </tbody>
            </table>
          </b-dropdown>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{ formatDate(message.time) }}</small>
        <!-- Mail Action DD -->
        <b-dropdown no-caret right toggle-class="p-0" variant="link">
          <template #button-content>
            <feather-icon class="ml-50 text-body" icon="MoreVerticalIcon" size="17"/>
          </template>

          <b-dropdown-item @click="replyOnAnEmail('reply')">
            <feather-icon icon="CornerUpLeftIcon"/>
            <span class="align-middle ml-50">Répondre</span>
          </b-dropdown-item>

          <b-dropdown-item @click="replyOnAnEmail('replyAll')">
            <feather-icon icon="CornerUpRightIcon"/>
            <span class="align-middle ml-50">Répondre à tous</span>
          </b-dropdown-item>

          <b-dropdown-item @click="replyOnAnEmail('transfer')">
            <feather-icon icon="TrashIcon"/>
            <span class="align-middle ml-50">Transférer</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-card-header>

    <b-card-body v-if="message.message" class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="!IsContainsStyle(message.message)" class="mail-message" v-html="message.message"/>
      <iframe v-else :srcdoc="message.message" frameborder="0" style="height: 40vh; width: 100%"/>
      <!-- eslint-enable -->
    </b-card-body>

    <b-card-footer v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon icon="PaperclipIcon" size="16"/>
          <h5 class="font-weight-bolder text-body mb-0 ml-50">{{ message.attachments.length }}
            Attachment{{ message.attachments.length > 1 ? 's' : '' }}</h5>
        </div>

        <div class="d-flex flex-column">
          <!--          @click="downloadBase64(attachment.base64, attachment.fileName)"-->
          <b-link v-for="(attachment, index) in message.attachments" :key="index" @click="displayDocument(attachment.documentId, attachment.fileName, attachment.name)" :class="{ 'mt-75': index }">
            <b-img :src="require('@/assets/images/icons/' + attachment.thumbnail)" class="mr-50" width="16px"/>
            <span class="text-muted font-weight-bolder align-text-top">{{ attachment.name }}</span>
            <span class="text-muted font-small-2 ml-25">({{ attachment.size }})</span>
          </b-link>
        </div>
      </div>
    </b-card-footer>

    <b-modal v-if="can('Visualize other documents')" id="modal-display-document" ref="modal-display-document"
             :title="document.nameToDownload" cancel-title="Fermer" cancel-variant="outline-secondary" ok-title="Télécharger"
             @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form ref="form">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.nameToDownload" :src="document.base64" fluid/>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BImg,
  BLink,
  BModal
} from 'bootstrap-vue'
import { formatDate, formatDateTime } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple
  },
  components: {
    BModal,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BLink,
    BImg
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
    }
  },
  methods: {
    replyOnAnEmail(typeAction) {
      this.$emit('reply-on-an-email-from-card', typeAction)
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => { 
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (res.data.data.isDownload) {
                this.downloadDocument(res.data.data.base64, NomDuDocument)
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = NomDuDocument
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.downloadDocument(res.data.data.base64, NomDuDocument)
              }
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', 'error')
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
  },
  setup() {
    // convert base64 and download it
    const downloadBase64 = (base64, name) => {
      const a = document.createElement('a')
      a.href = base64
      a.download = name
      a.click()
    }

    const IsContainsStyle = message => !!message.includes('<style')
    return {
      formatDate,
      formatDateTime,

      // Actions
      downloadBase64,
      IsContainsStyle
    }
  }
}
</script>

<style></style>
