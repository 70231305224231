<template>
  <b-sidebar id="sync-mails" ref="sideBarSync" backdrop bg-variant="white" no-header right shadow
             sidebar-class="sidebar-lg"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <feather-icon v-if="imap.selectedProvider !== null" class="mr-1 cursor-pointer" icon="ArrowLeftIcon" size="16"
                      @click="addAccount(null)"
        />
        <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Synchronisation e-mail</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
      </div>

      <b-form>
        <b-row v-if="imap.selectedProvider === null" class="p-2 mx-0">
          <b-col v-for="(btn, index) in ButtonOptions" :key="index" class="mt-2" cols="12">
            <template v-if="btn.provider == 'autre'">
<b-button v-ripple.400="btn.ripple" :variant="btn.variant" block @click="addAccountAutre(btn.provider, btn.value, btn.variant)"> {{ btn.value }} </b-button>
            </template>
            <!-- <template v-else-if="btn.provider == 'gmail'">
              <b-button v-ripple.400="btn.ripple" :variant="btn.variant"
                        block @click="handleClickSignInGmail(btn)"
              > {{ btn.value }}
              </b-button>
            </template> -->
            <template v-else>
              <b-button v-ripple.400="btn.ripple" :variant="btn.variant" block
                        @click="addAccount(btn.provider, btn.value, btn.variant)"
              > {{ btn.value }}
              </b-button>
            </template>
          </b-col>
          <!--<b-col class="mt-2" cols="12">
            <template v-if="showautre">
              <b-form-select v-model="autreemail"
                             @change="addAccount(autreemail.provider, autreemail.value, autreemail.variant)"
              >
                <b-form-select-option :value="null">--Selectionner--</b-form-select-option>
                <b-form-select-option v-for="(autre, index) in autreOptions" :key="index" :value="autre">{{
                    autre.value
                  }}
                </b-form-select-option>
              </b-form-select>
               <b-col v-for="(autre, index) in autreOptions" :key="index" class="mt-2" cols="12">
                  <b-button v-ripple.400="autre.ripple" :variant="autre.variant" block @click="addAccount(autre.provider, autre.value, autre.variant)"> {{ autre.value }} </b-button>
              </b-col>
            </template>
          </b-col> -->
          <template v-if="warning_gmail">
            <b-col class="mt-2" cols="12">
              <p><feather-icon icon="AlertTriangleIcon" class="text-warning" size="16"/> suite aux dernières évolutions de Google, vous pouvez rencontrer une <b>erreur d'autorisation (erreur 403 : access_denied).</b> Nos équipes techniques doivent alors enregistrer votre boite gmail auprès de ce dernier. Merci de renseigner votre adresse mail ici :</p>

              <validation-observer ref="supportGmailRules">
                <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                  <b-input-group>
                        <b-form-input v-model="support_email" :state="errors.length > 0 ? false : null"
                                      placeholder="Votre email"
                                      type="email"
                        />
                    <b-input-group-append>
                      <b-button variant="primary" @click="support_gmail">
                        <feather-icon icon="SendIcon"/>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </validation-observer>

              <p class="mt-2">
                Ceci ne devrait pas prendre plus de 30 mn. Vous en serez informé(e) par mail à cette même adresse.<br>
                Vous devrez ensuite effectuer de nouveau la procédure de synchronisation pour que celle-ci puisse se faire.
              </p>
            </b-col>
          </template>
        </b-row>
        <b-row v-else class="p-1 m-0">
          <validation-observer ref="simpleRules">
            <b-overlay :show="showLoading" no-wrap/>
            <b-row class="mt-2 m-0 w-100">

              <b-col cols="12">
                <b-alert :variant="imap.providerVariant" show>
                  <h4 class="alert-heading">Service de connexion</h4>
                  <div class="alert-body" v-if="!imap.showautre">
                    <span>Le type de compte est {{ imap.providerValue }}.</span>
                  </div>
                </b-alert>
              </b-col>

              <b-col class="mt-2" cols="12">
                <b-form-group label="E-mail *" label-for="email">
                  <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                    <b-form-input v-model="imap.email" :state="errors.length > 0 ? false : null"
                                  placeholder="Votre email"
                                  type="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <template v-if="imap.showautre">
                <b-col cols="12">
                  <b-form-group label="Hôte *" label-for="host">
                    <validation-provider #default="{ errors }" name="Hôte" rules="required">
                      <b-form-input v-model="imap.host" :state="errors.length > 0 ? false : null" type="text" placeholder="Hôte" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Port *" label-for="port">
                    <validation-provider #default="{ errors }" name="Port" rules="required">
                      <b-form-input v-model="imap.port" :state="errors.length > 0 ? false : null" type="text" placeholder="Port" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Cryptage *" label-for="encryption">
                    <validation-provider #default="{ errors }" name="Cryptage" rules="required">
                        <b-form-select v-model="imap.encryption" :state="errors.length > 0 ? false : null">
                          <b-form-select-option :value="null">--Selectionner--</b-form-select-option>
                          <b-form-select-option v-for="(cryptage, index) in cryptageOption" :key="index" :value="cryptage.value">{{ cryptage.text }}</b-form-select-option>
                        </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- <b-col cols="12">
                  <b-form-group label="Protocol *" label-for="protocol">
                    <validation-provider #default="{ errors }" name="Protocol" rules="required">
                      <b-form-input v-model="imap.protocol" :state="errors.length > 0 ? false : null" type="text" placeholder="Protocol" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <b-col cols="12">
                  <b-form-group label="Hôte sortant *" label-for="host_outgoing">
                    <validation-provider #default="{ errors }" name="Hôte sortant" rules="required">
                      <b-form-input v-model="imap.host_outgoing" :state="errors.length > 0 ? false : null" type="text" placeholder="Hôte sortant" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Port sortant *" label-for="port_outgoing">
                    <validation-provider #default="{ errors }" name="Port sortant" rules="required">
                      <b-form-input v-model="imap.port_outgoing" :state="errors.length > 0 ? false : null" type="text" placeholder="Port sortant" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Cryptage sortant *" label-for="encryption_outgoing">
                    <validation-provider #default="{ errors }" name="Cryptage sortant" rules="required">
                      <!-- <b-form-input v-model="imap.encryption_outgoing" :state="errors.length > 0 ? false : null" type="text" placeholder="Cryptage sortant" /> -->
                        <b-form-select v-model="imap.encryption_outgoing" :state="errors.length > 0 ? false : null">
                          <b-form-select-option :value="null">--Selectionner--</b-form-select-option>
                          <b-form-select-option v-for="(cryptage, index) in cryptageOption" :key="index" :value="cryptage.value">{{ cryptage.text }}</b-form-select-option>
                        </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </template>

              <b-col cols="12">
                <b-form-group label="Mot de passe *" label-for="mot-de-passe">
                  <validation-provider #default="{ errors }" name="Mot de passe" rules="required" vid="Password">
                    <b-input-group>
                      <b-form-input v-model="imap.password" :state="errors.length > 0 ? false : null"
                                    :type="passwordFieldType" placeholder="Votre mot de passe"
                      />
                      <b-input-group-append is-text>
                        <feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
                                      @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col cols="12">
                <b-form-group label="Confirmer le mot de passe *" label-for="mot-de-passe">
                  <validation-provider #default="{ errors,failedRules }" name="Confirmer le mot de passe"
                                       rules="required|confirmed:Password"
                  >
                    <b-form-input v-model="imap.confirmed_password" :state="errors.length > 0 ? false : null"
                                  :type="passwordFieldType" placeholder="Confirmez le mot de passe"
                    />
                    <small v-if=" failedRules && failedRules.confirmed " class="text-danger">Les mots de passes que vous
                      avez saisis ne correspondent pas</small>
                    <small v-else class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <p v-if="imap.providerValue === 'Gmail' " class="mt-0 ml-1" > Retrouvez la procédure dans Mes tutos (Mes vidéos) rubrique Ma messagerie
                <!-- <a class="d-block text-right" href="https://bubbleinsupport.zendesk.com/hc/fr/articles/10093660054301-Comment-synchroniser-sa-boite-Gmail-avec-BubbleIn-"
                   style="text-decoration:underline" target="_blank"
                >Cliquez içi</a> -->
              </p>

            </b-row>
          </validation-observer>
        </b-row>
      </b-form>
    </template>

    <template #footer>
      <div class="p-2 border-top text-danger cursor-pointer text-right">
        <b-button class="mr-1" variant="danger" @click="closeForm"> Fermer</b-button>
        <b-button v-if="imap.selectedProvider !== null" type="submit" variant="primary" @click.prevent="sendData">
          Synchroniser
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BAlert,
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BRow,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { confirmed, email, required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BButton,
    BSidebar,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BAlert,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showLoading: false,
      hypertext: '',
      newWindow: null,
      ButtonOptions: [
        {
          provider: 'gmail',
          value: 'Gmail',
          variant: 'danger',
          ripple: 'rgba(255, 255, 255, 0.15)'
        },
        {
          provider: 'outlook',
          value: 'Outlook',
          variant: 'secondary',
          ripple: 'rgba(255, 255, 255, 0.15)'
        },
                { provider: 'ovh', value: 'OVH', variant: 'success', ripple: 'rgba(255, 255, 255, 0.15)' },
        { provider: 'gandi', value: 'Gandi', variant: 'info', ripple: 'rgba(255, 255, 255, 0.15)' },
        // {
        //   provider: 'yahoo',
        //   value: 'Yahoo',
        //   variant: 'warning',
        //   ripple: 'rgba(255, 255, 255, 0.15))'
        // },
        {
          provider: 'autre',
          value: 'Autre',
          variant: 'primary',
          ripple: 'rgba(255, 255, 255, 0.15)'
        },
      ],
      autreOptions: [
        {
          provider: 'ovh',
          value: 'OVH',
          variant: 'primary',
          ripple: 'rgba(255, 255, 255, 0.15)'
        },
        // { provider: 'amen', value: 'amen', variant: 'primary', ripple: 'rgba(255, 255, 255, 0.15)' },
        {
          provider: 'gandi',
          value: 'Gandi',
          variant: 'primary',
          ripple: 'rgba(255, 255, 255, 0.15)'
        },
      ],
      cryptageOption:[
        { text:'Aucun',value:'NONE' },
        { text:'SSL',value:'SSL' },
        { text:'TLS',value:'TLS' }
      ],
      imap: {
        providerVariant: null,
        providerValue: null,
        selectedProvider: null,
        email: null,
        password: null,
        confirmed_password: null,
        host:null,
        port:null,
        encryption:null,
        protocol:'imap',
        host_outgoing:null,
        port_outgoing:null,
        encryption_outgoing:null,
        showautre: false,
        refresh_token: null,
        expires_in: 0,
        auth_code: null
      },
      required,
      email,
      confirmed,
      showautre: false,
      autreemail: null,
      warning_gmail: false,
      support_email:null,
      current_user: JSON.parse(localStorage.getItem('userData')),
      send_support:false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    popupCenter(url, title, w, h) {
      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - w) / 2 / systemZoom + dualScreenLeft
      const top = (height - h) / 2 / systemZoom + dualScreenTop
      this.newWindow = window.open(url, title,
          `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
      )
      if (this.newWindow.focus) {
        window.addEventListener('message',  this.affctingDataFromGmailApi.bind(event))
      }
    },
    affctingDataFromGmailApi(e) {
        if (e.data) {
          let data = JSON.stringify(e.data)
          const dataToJson = JSON.parse(data)
          if (this.newWindow.closed && dataToJson.refresh_token) {
            this.imap.providerVariant = dataToJson.providerVariant
            this.imap.providerValue = dataToJson.providerValue
            this.imap.email = dataToJson.email
            this.imap.confirmed_password = dataToJson.confirmed_password
            this.imap.refresh_token = dataToJson.refresh_token
            this.imap.expires_in = dataToJson.expires_in
            this.imap.auth_code = dataToJson.auth_code
            this.imap.password = dataToJson.password
            this.imap.selectedProvider = dataToJson.selectedProvider
            this.sendData()
          }
        }
    },
    sendData(){
      this.recodSync()
      window.removeEventListener('message', this.affctingDataFromGmailApi.bind(event))
    },
    async handleClickSignInGmail(btn) {
      this.warning_gmail = true
      try {

        this.$http.get('boite_mail/redirectGmail')
            .then(res => {
              this.popupCenter(res.data, '', 400, 600)
            })
            .catch(err => {
              console.error(err)
            })
      } catch (error) {
        console.error(error)
        return null
      }
    },
    support_gmail(){
      let identifiant = this.current_user.name
      let denomination = this.current_user.courtier_user[0].courtier.personne_morale.denomination_commercial
      this.$refs.supportGmailRules.validate().then(success => {
        if (success) {
          this.$http
          .post('boite_mail/supportgmail', {"email":this.support_email,"identifiant":identifiant,"denomination":denomination})
          .then(res => {
            if(res.data.success){
              this.messageToast(res.data.message, 'Succès', 'success')
              this.support_email = null
              this.closeForm()
            }
          })
          .catch(err => {
            console.error(err)
            this.messageToast(err, 'Erreur', 'danger')
          })
        }
      })
    },
    closeForm() {
      this.$refs.sideBarSync.hide()
      this.addAccount(null)
      this.imap.showautre = false
      this.warning_gmail = false
    },
    show_autre() {
      this.imap.showautre = true
    },
    addAccount(btn, value, variant) {
      this.imap.showautre = false
      this.imap.providerVariant = variant
      this.imap.providerValue = value
      this.imap.email = ''
      this.imap.confirmed_password = ''
      this.imap.password = ''
      this.imap.selectedProvider = btn
    },
    addAccountAutre(btn, value, variant) {
      this.imap.showautre = true
      this.imap.providerVariant = variant
      this.imap.providerValue = value
      this.imap.email = ''
      this.imap.confirmed_password = ''
      this.imap.password = ''
      this.imap.selectedProvider = btn
    },
    recodSync() {
      this.showLoading = true
      this.$http
          .post('boite_mail/add_new_account', this.imap)
          .then(res => {
            if (res.data.success) {
              this.showLoading = false
              this.$emit('boite-sync-returned', res.data.data, res.data.message)
              this.closeForm()
            } else {
              this.showLoading = false
              this.$swal({
                title: 'Erreur',
                text: res.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          })
          .catch(err => {
            this.showLoading = false
            this.$swal({
              title: 'Erreur',
              text: err.response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            console.error(err)
          })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.recodSync()
            }
          })
    }
  }
}
</script>
