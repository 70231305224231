<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <b-overlay :show="showLoading" no-wrap />
    <div :class="{ show: mqShallShowLeftSidebar }" class="body-content-overlay" @click="mqShallShowLeftSidebar = false" />

    <!-- Email List -->
    <div class="email-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon class="cursor-pointer" icon="MenuIcon" size="21" @click="mqShallShowLeftSidebar = true" />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between" style="width: 98%">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchedBy" placeholder="Recherche" @keyup.enter="updateRouteQuery" />
            <b-input-group-append>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" class="btn-icon rounded-circle">
                <feather-icon icon="ArrowRightIcon" @click.prevent="updateRouteQuery" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <!-- App Action Bar -->
      <div class="app-action">
        <div class="action-left">
          <b-form-checkbox :disabled="emailsLocal.length <= 0" :checked="selectAllEmailCheckbox" :indeterminate="isSelectAllEmailCheckboxIndeterminate" @change="selectAllCheckboxUpdate"> Tout sélectionner {{ selectedEmails.length > 0 ? '( ' + selectedEmails.length + ' )' : '' }} </b-form-checkbox>
        </div>

        <div class="d-flex align-items-center">
          <!-- Update Mail Folder Dropdown -->
          <b-dropdown v-show="selectedEmails.length" v-b-tooltip.hover.top="'Étiquettes'" class="ml-1" no-caret right toggle-class="p-0" variant="link">
            <template #button-content>
              <feather-icon class="align-middle text-body" icon="TagIcon" size="17" />
            </template>
            <b-dropdown-item @click="updateSelectedEmailsLabel('Reseaux')">
              <span class="mr-50 bullet bullet-warning bullet-sm" />
              <span>Réseaux</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('Assureurs')">
              <span class="mr-50 bullet bullet-info bullet-sm" />
              <span>Assureurs</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('Clients')">
              <span class="mr-50 bullet bullet-secondary bullet-sm" />
              <span>Clients</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('Comptabilité')">
              <span class="mr-50 bullet bullet-danger bullet-sm" />
              <span>Comptabilité</span>
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown v-show="selectedEmails.length" v-b-tooltip.hover.top="'Lu/ Non lu'" no-caret right toggle-class="p-0" variant="link">
            <template #button-content>
              <feather-icon class="text-body cursor-pointer ml-1" icon="MailIcon" size="17" />
            </template>

            <b-dropdown-item @click="markSelectedEmailsAsUnreadOrRead(false)">
              <span class="align-middle ml-50">Marquer comme non lu</span>
            </b-dropdown-item>

            <b-dropdown-item @click="markSelectedEmailsAsUnreadOrRead(true)">
              <span class="align-middle ml-50">Marquer comme lu</span>
            </b-dropdown-item>
          </b-dropdown>

          <feather-icon v-show="selectedEmails.length && $route.params.folder !== 'trash'" v-b-tooltip.hover.top="'Supprimer'" class="cursor-pointer ml-1" icon="TrashIcon" size="17" @click="deleteEmail" />

          <b-button size="sm" v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="flat-secondary" class="cursor-pointer ml-1 p-0" @click="fetchEmails(true, true, false, false)"> <feather-icon icon="RefreshCwIcon" v-b-tooltip.hover.top="'Actualiser'" size="17" class="mr-50" /><span class="align-middle">Actualiser</span> </b-button>
        </div>
      </div>

      <!-- App Actions filter -->

      <div class="app-action">
        <div class="action-left">
          <!-- v-if="showUnRead" -->
          <b-button :disabled="emailsLocal.length <= 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="flat-secondary" @click="setPaginateRequestFR('A')">
            <feather-icon class="ml-50" icon="ListIcon" />
            <span class="align-middle">Tous les emails</span></b-button
          >
          <b-button :disabled="emailsLocal.length <= 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="flat-secondary" @click="setPaginateRequestFR('UR')">
            <feather-icon class="ml-50" icon="FilterIcon" />
            <span class="align-middle">Emails non lus</span></b-button
          >
        </div>
        <div class="align-items-center">
          <b-button :disabled="emailsLocal.length <= 0" v-if="getPaginateRequestFP==='A'" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="flat-secondary" @click="setPaginateRequestFP('R')"
            ><span class="align-middle">Le plus récent</span>
            <feather-icon class="ml-50" icon="ArrowDownIcon" />
          </b-button>
          <b-button :disabled="emailsLocal.length <= 0" v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="flat-secondary" @click="setPaginateRequestFP('A')"
            ><span class="align-middle">Le plus ancien</span>
            <feather-icon class="ml-50" icon="ArrowUpIcon" />
          </b-button>
        </div>
      </div>
      <!-- Emails List -->
        <vue-perfect-scrollbar ref="scrollContainer" :settings="perfectScrollbarSettings" class="email-user-list scroll-area" style="height: calc(100% - calc(3.49rem + 7.5rem))">
          <ul class="email-media-list">
            <b-media v-for="email in emails" :key="email.id" :class="{ 'mail-read': email.isRead }" no-body tag="li" @click="updateEmailViewData(email)">
              <b-media-aside class="media-left mr-50">
                <b-avatar :src="email.from.avatar" class="avatar" size="40" variant="primary" />
                <div class="user-action">
                  <b-form-checkbox :checked="selectedEmails.includes(email.id)" @change="toggleSelectedMail(email.id)" @click.native.stop />
                </div>
              </b-media-aside>

              <b-media-body>
                <div class="mail-details">
                  <div class="mail-items">
                    <h5 :class="{ 'font-weight-bolder': !email.isRead }" class="mb-25">
                      {{ email.from.name }}
                    </h5>
                    <span :class="{ 'font-weight-bolder': !email.isRead }" class="text-truncate">{{ email.subject }}</span>
                  </div>
                  <div class="mail-meta-item">
                    <feather-icon v-if="email.attachments.length" icon="PaperclipIcon" />
                    <span :class="`bullet-${resolveLabelColor(email.emailLabel)}`" class="mx-50 bullet bullet-sm" />
                    <span class="mail-date">{{
                      formatDateToMonthShort(email.time, {
                        hour: 'numeric',
                        minute: 'numeric'
                      })
                    }}</span>
                  </div>
                </div>

                <div class="mail-message">
                  <p class="text-truncate mb-0">
                    <span v-html="filterTags(email.message)" />
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </ul>
          <div :class="{ show: !emails.length }" class="no-results">
            <h5>Aucun message trouvé</h5>
          </div>
          <div>
            <b-overlay :show="isLoadingMore" rounded="lg" opacity="0.6">
              <template #overlay>
                <div class="text-center mt-3">
                  <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                  <p id="cancel-label">Chargement...</p>
                </div>
              </template>
            </b-overlay>
          </div>
        </vue-perfect-scrollbar>
    </div>
    <!-- Email View/Detail -->
    <email-view :class="{ show: showEmailDetails }" :email-view-data="emailViewData" :emails-contacts="emailsContacts" :opended-email-meta="opendedEmailMeta" @remind-me-action="RemindMeAction" @affect-client-action="affectClientAction" @close-email-view="showEmailDetails = false" @move-email-to-folder="moveOpenEmailToFolder" @remove-email="removeEmail" @toggle-email-starred="toggleStarred(emailViewData)" @update-email-label="updateOpenEmailLabel" @mark-email-unread="markOpenEmailAsUnread" @change-opened-email="changeOpenedEmail" @reply-on-an-email-from-view="replyOnAnEmail" />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar :class="{ show: mqShallShowLeftSidebar }" :emails-meta="emailsMeta" :shall-show-email-compose-modal.sync="shallShowEmailComposeModal" :type-evenement.sync="typeEvenement" @close-left-sidebar="mqShallShowLeftSidebar = false" @fetch-emails="fetchEmails(false, true, false, false)" @is-email-synchronized="isEmailSynchronized" />
    </portal>

    <!-- Compose Email Modal -->
    <email-compose v-if="renderComponent" v-model="shallShowEmailComposeModal" :email-view-data="typeAction ? emailViewData : null" :emails-contacts="emailsContacts" :type-action="typeAction" :type-evenement="typeEvenement" @returned-email-saved="returnedEmailSaved" :email-to-send="emailToSend" :copier-carbon-to-send="copierCarbonToSend" />
  </div>
</template>

<script>
import store from '@/store'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import { BAvatar, BButton, BDropdown, BDropdownItem, BFormCheckbox, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BMedia, BMediaAside, BMediaBody, BOverlay, VBTooltip, BSpinner } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vue } from 'vue-property-decorator'
import EmailLeftSidebar from './EmailLeftSidebar.vue'
import EmailView from './EmailView.vue'
import emailStoreModule from './emailStoreModule'
import useEmail from './useEmail'
import EmailCompose from './EmailCompose.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    BOverlay,
    BSpinner,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    EmailLeftSidebar,
    EmailView,
    EmailCompose
  },
  data() {
    return {
      renderComponent: true,
      emailToSend: null,
      copierCarbonToSend: null
    }
  },
  beforeMount() {
    if (this.$route.params) {
      if (this.$route.params.email && this.$route.params.operation === 'show') {
        this.updateEmailViewData(this.$route.params.email)
      } else if (this.$route.params.email && this.$route.params.email && this.$route.params.operation === 'send') {
        this.emailToSend = this.$route.params.email
        this.shallShowEmailComposeModal = true
        if (this.$route.params.copierCarbon) {
          this.copierCarbonToSend = this.$route.params.copierCarbon
        }
      }
    }
  },
  created() {
    this.setPaginateRequestFP('R')
  },
  mounted() {
    const el = this.$refs.scrollContainer.$el;
    el.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    const el = this.$refs.scrollContainer.$el;
    el.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    isEmailSynchronized(value) {
      if (value === 'synchronized') {
        this.isEmailSynchronizedValue = true
        this.fetchEmails(true, true, false, false)
      } else if (value === 'desynchronized') {
        this.isEmailSynchronizedValue = false
        this.emails = []
        this.emailsLocal = []
      }
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
    },
    handleScroll() {
      const el = this.$refs.scrollContainer.$el;
      if (el.scrollTop + el.clientHeight >= el.scrollHeight && this.$store.getters['messageries/getPaginateResultNextPage']) {
        this.fetchEmails(false, false, true, true)
      }
    },
  },
  watch: {
    shallShowEmailComposeModal: {
      handler(val) {
        if (!val) {
          this.typeAction = null
        }
        // console.log(val)
      }
    },
  },
  computed: {
    getPaginateRequestFP() {
      return this.$store.getters['messageries/getPaginateRequestFP'];
    }
  },
  // typeAction.value = null
  setup() {
    const EMAIL_APP_STORE_MODULE_NAME = 'messageries'

    // Register module
    if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
    // })

    const { route, router } = useRouter()
    const { resolveLabelColor } = useEmail()

    // Emails & EmailsMeta
    const emails = ref([])
    const emailsLocal = ref([])
    const emailsMeta = ref({})
    const emailsContacts = ref([])
    const showLoading = ref(false)
    const isLoadingMore = ref(false)
    const typeAction = ref(null)
    const searchedBy = ref(null)
    const showUnRead = ref(false)
    const isEmailSynchronizedValue = ref(false)

    // Route Params
    const routeParams = computed(() => route.value.params)

    const changeKeyBeforeAction = (EmailsList, key, value) => {
      EmailsList.value.forEach(id => {
        // eslint-disable-next-line camelcase
        const index_email = emails.value.findIndex(el => el.id === id)

        emails.value[index_email][key] = value
        // eslint-disable-next-line camelcase
        const index_email_local = emailsLocal.value.findIndex(el => el.id === id)
        emailsLocal.value[index_email_local][key] = value
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })

    const updateRouteQuery = () => {
      store.commit('messageries/setPaginateRequestS', searchedBy.value)
      fetchEmails(false, true, false, false)
    }
    const fetchUsersAndClientContact = () => {
      store.dispatch('messageries/fetchUsersAndClientContact').then(response => {
        emailsContacts.value = response.data
      })
    }
    const setPaginateRequestFP = val => {
      store.commit('messageries/setPaginateRequestFP', val)
      fetchEmails(false, true, false, false)
    }

    const setPaginateRequestFR = val => {
      store.commit('messageries/setPaginateRequestFR', val)
      fetchEmails(false, true, false, false)
    }

    const fetchEmails = (fetch, showLoadingPage = true, isConcat = false, isPaginate = false) => {
      if (showLoadingPage) {
        showLoading.value = true
      } else {
        isLoadingMore.value = true
      }
      store.commit('messageries/setRefresh', fetch)
      store.commit('messageries/setIsPaginate', isPaginate)
      store
        .dispatch('messageries/fetchEmails', fetch)
        .then(response => {
          if (response.data) {
            if (isConcat) {
              emails.value = emails.value.concat(response.data.data.events_returned)
              emailsLocal.value = emails.value.concat(response.data.data.events_returned)
            } else {
              emails.value = response.data.data.events_returned
              emailsLocal.value = response.data.data.events_returned
            }
            if (store.getters['messageries/getPaginateRequest'].FD === 'R' && store.getters['messageries/getPaginateRequest'].FT === 'A') {
              store.commit('messageries/setCountMessageUnread', response.data.unReadCount)
            }
            store.commit('messageries/setPaginateResult', response.data)
          } else {
            emails.value = []
            emailsLocal.value = []
            store.commit('messageries/setCountMessageUnread', 0)
          }
        })
        .catch(error => {
          emails.value = []
          emailsLocal.value = []
          Vue.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: error.response.data['errors'][0],
              variant: 'danger'
            }
          })
        })
        .finally(() => {
          showLoading.value = false
          isLoadingMore.value = false
        })
    }

    onMounted(() => {
      fetchEmails(false, true, false, false)
    })
    fetchUsersAndClientContact()

    // ------------------------------------------------
    // Mail Selection
    // ------------------------------------------------
    const selectedEmails = ref([])
    const toggleSelectedMail = mailId => {
      const mailIndex = selectedEmails.value.indexOf(mailId)

      if (mailIndex === -1) {
        selectedEmails.value.push(mailId)
      } else {
        selectedEmails.value.splice(mailIndex, 1)
      }
    }
    const selectAllEmailCheckbox = computed(() => emails.value.length && emails.value.length === selectedEmails.value.length)
    const isSelectAllEmailCheckboxIndeterminate = computed(() => Boolean(selectedEmails.value.length) && emails.value.length !== selectedEmails.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedEmails.value = val ? emails.value.map(mail => mail.id) : []
    }
    // ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

    // ------------------------------------------------
    // Mail Actions
    // ------------------------------------------------

    const toggleStarred = email => {
      store
        .dispatch('messageries/updateEmail', {
          emailIds: [email.id],
          dataToUpdate: { isStarred: !email.isStarred }
        })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          email.isStarred = !email.isStarred
        })
    }
    const moveSelectedEmailsToFolder = folder => {
      showLoading.value = true
      store
        .dispatch('messageries/updateEmail', {
          emailIds: selectedEmails.value,
          dataToUpdate: { folder }
        })
        .then(() => {
          fetchEmails(false, true, false, false)
        })
        .finally(() => {
          selectedEmails.value = []
          showLoading.value = false
        })
    }
    const updateSelectedEmailsLabel = label => {
      showLoading.value = true
      store
        .dispatch('messageries/updateEmailLabels', {
          emailIds: selectedEmails.value,
          label
        })
        .then(() => {
          changeKeyBeforeAction(selectedEmails, 'emailLabel', label)
        })
        .finally(() => {
          selectedEmails.value = []
          showLoading.value = false
        })
    }
    const markSelectedEmailsAsUnreadOrRead = (isRead, cleanSelectedEmails = true) => {
      showLoading.value = true
      store
        .dispatch('messageries/updateEmail', {
          emailIds: selectedEmails.value,
          dataToUpdate: {
            isRead,
            status: 'changeIsRead'
          }
        })
        .then(() => {
          changeKeyBeforeAction(selectedEmails, 'isRead', isRead)
          const countHowManyEmailUnreadOrRead = emailsLocal.value.filter(item => item.isRead === false).length
          store.commit('messageries/setCountMessageUnread', countHowManyEmailUnreadOrRead)
        })
        .finally(() => {
          if (cleanSelectedEmails) {
            selectedEmails.value = []
            showLoading.value = false
          }
        })
    }

    // ------------------------------------------------
    // Email Details
    // ------------------------------------------------

    const showEmailDetails = ref(false)
    const emailViewData = ref({})
    const opendedEmailMeta = computed(() => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      return {
        hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
        hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1])
      }
    })

    const updateEmailViewData = email => {
      showLoading.value = true
      // Mark email is read
      if (!email.isRead) {
        store
          .dispatch('messageries/updateEmail', {
            emailIds: [email.id],
            dataToUpdate: {
              isRead: true,
              status: 'changeIsRead'
            }
          })
          .then(() => {
            // If opened email is unread then decrease badge count for email meta based on email folder
            if (!email.isRead && (email.folder === 'inbox' || email.folder === 'spam')) {
              emailsMeta.value[email.folder] -= 1
            }

            // eslint-disable-next-line no-param-reassign
            email.isRead = true
            const getCountMessageUnread = store.getters['messageries/getCountMessageUnread']
            store.commit('messageries/setCountMessageUnread', getCountMessageUnread - 1)
          })
          .finally(() => {
            emailViewData.value = email
            showEmailDetails.value = true
            showLoading.value = false
          })
      } else {
        emailViewData.value = email
        showEmailDetails.value = true
        showLoading.value = false
      }
    }
    const returnedEmailSaved = data => {
      if (store.getters['messageries/getPaginateRequest'].FD === 'S') {
        emailsLocal.value.unshift(data[0])
      }
      // initializeFilter()
    }
    const deleteEmail = (iscloseShowEmailDetails = false) => {
      // delete Emails selected
      Vue.swal({
        title: '',
        text: 'Etes-vous sûr de vouloir supprimer le message ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          showLoading.value = true
          store
            .dispatch('messageries/deleteEmail', {
              emailIds: selectedEmails.value
            })
            .then(() => {
              emails.value = emails.value.filter(item => !selectedEmails.value.includes(item.id))
              emailsLocal.value = emailsLocal.value.filter(item => !selectedEmails.value.includes(item.id))
              // showToast('success', 'Le message a été supprimer avec succès', 'BellIcon')
              Vue.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: 'Le message a été supprimé avec succès',
                  variant: 'success'
                }
              })
            })
            .finally(() => {
              // eslint-disable-next-line no-self-assign
              emails.value = emails.value
              // eslint-disable-next-line no-self-assign
              emailsLocal.value = emailsLocal.value
              selectedEmails.value = []

              if (iscloseShowEmailDetails) {
                showEmailDetails.value = false
              }
              const countHowManyEmailUnreadOrRead = emailsLocal.value.filter(item => item.isRead === false).length
              store.commit('messageries/setCountMessageUnread', countHowManyEmailUnreadOrRead)
              showLoading.value = false
            })
        }
      })
    }
    const moveOpenEmailToFolder = folder => {
      selectedEmails.value = [emailViewData.value.id]
      // moveSelectedEmailsToFolder(folder)
      deleteEmail()
    }
    const removeEmail = () => {
      selectedEmails.value = [emailViewData.value.id]
      deleteEmail(true)
    }
    const updateOpenEmailLabel = label => {
      selectedEmails.value = [emailViewData.value.id]
      updateSelectedEmailsLabel(label)

      // Update label in opened email

      // const labelIndex = emailViewData.value.labels.indexOf(label)
      // if (labelIndex === -1) emailViewData.value.labels.push(label)
      // else emailViewData.value.labels.splice(labelIndex, 1)
      emailViewData.value.emailLabel = label

      selectedEmails.value = []
    }
    const replyOnAnEmail = typeActionEmit => {
      // eslint-disable-next-line no-param-reassign
      typeAction.value = typeActionEmit
      // eslint-disable-next-line no-use-before-define
      shallShowEmailComposeModal.value = true
    }
    const markOpenEmailAsUnread = isRead => {
      selectedEmails.value = [emailViewData.value.id]
      markSelectedEmailsAsUnreadOrRead(isRead)

      // selectedEmails.value = []
      showEmailDetails.value = false
    }
    const RemindMeAction = (type, value, evenementUserId) => {
      store.dispatch('messageries/updateEmail', {
        emailIds: [evenementUserId],
        dataToUpdate: {
          type_rappel: type,
          valeur_rappel: value,
          status: 'ReminderForEvent'
        }
      })
    }
    const affectClientAction = (tier, evenementId) => {
      store.dispatch('messageries/updateEmail', {
        emailIds: [evenementId],
        dataToUpdate: {
          tier,
          status: 'affecterClient'
        }
      })
    }
    const changeOpenedEmail = (dir, isRead) => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      const newEmailIndex = dir === 'previous' ? openedEmailIndex - 1 : openedEmailIndex + 1
      emailViewData.value = emails.value[newEmailIndex]

      selectedEmails.value = [emailViewData.value.id]
      markSelectedEmailsAsUnreadOrRead(isRead, false)
      changeKeyBeforeAction(selectedEmails, 'isRead', isRead)

      selectedEmails.value = []
    }

    // * If someone clicks on filter while viewing detail => Close the email detail view
    watch(routeParams, () => {
      showEmailDetails.value = false
    })

    // * Watcher to reset selectedEmails
    // ? You can also use showEmailDetails (instead of `emailViewData`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([emailViewData, routeParams], () => {
      selectedEmails.value = []
    })

    // * Watcher filter by Read and all
    watch(() => showUnRead.value,val => {
        if (val) {
          emails.value = emails.value.filter(item => item.isRead === false)
        }
      })

    // watchEffect(emails.value, () => {
    //   console.log(emails.value)
    //   // emails.value = this.$_.orderBy(val,  function (o) { return new moment(o.time) }, ['desc'])
    //   // emailsLocal.value = this.$_.orderBy(emailsLocal.value,  function (o) { return new moment(o.time) }, ['desc'])
    //   // .orderBy(emails.value , function (o) { return new moment(o.time) }, ['desc'])
    // })
    // Compose
    const shallShowEmailComposeModal = ref(false)
    const typeEvenement = ref('Message')

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta & Contacts
      emails,
      emailsMeta,
      emailsContacts,
      emailsLocal,
      showLoading,
      isLoadingMore,
      typeAction,
      isEmailSynchronizedValue,
      searchedBy,

      // Mail Selection
      selectAllEmailCheckbox,
      isSelectAllEmailCheckboxIndeterminate,
      selectedEmails,
      toggleSelectedMail,
      selectAllCheckboxUpdate,

      // Mail Actions
      toggleStarred,
      moveSelectedEmailsToFolder,
      updateSelectedEmailsLabel,
      markSelectedEmailsAsUnreadOrRead,
      changeKeyBeforeAction,
      returnedEmailSaved,
      setPaginateRequestFP,
      setPaginateRequestFR,

      // Email Details
      showEmailDetails,
      emailViewData,
      opendedEmailMeta,
      updateEmailViewData,
      deleteEmail,
      moveOpenEmailToFolder,
      removeEmail,
      updateOpenEmailLabel,
      replyOnAnEmail,
      markOpenEmailAsUnread,
      RemindMeAction,
      affectClientAction,
      changeOpenedEmail,
      showUnRead,
      fetchEmails,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,
      typeEvenement,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-email.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
