<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="boiteMails === null && boiteMailsId === null"
              block variant="primary" @click="$emit('update:shall-show-email-compose-modal', true)
                              $emit('close-left-sidebar')
                              $emit('update:typeEvenement', 'Message')">
              Nouveau message
            </b-button>      
          </div>
          
          <vue-perfect-scrollbar :settings=" perfectScrollbarSettings " class="sidebar-menu-list scroll-area">
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item class="cursor-pointer" v-for=" filter  in  emailFilters " :key=" filter.title + $route.path "
                :active="getPaginateRequest.FD === filter.value" :disabled=" boiteMails === null && boiteMailsId === null " @click="isDynamicRouteActive(filter, 'FD')">
                <feather-icon :icon=" filter.icon " class="mr-75" size="18" />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                <b-badge v-if=" emailsMeta[filter.title.toLowerCase()] " :variant=" resolveFilterBadgeColor(filter.title) "
                  class="float-right" pill>
                  {{ emailsMeta[filter.title.toLowerCase()] }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
            <h6 class="section-label mt-3 mb-1 px-2">
              Étiquettes
              {{ getPaginateRequest.FD === 'S' ? '( Messages envoyés )' : '( Boîte de réception )' }}
            </h6>

            <b-list-group class="list-group-labels">
              <b-list-group-item class="cursor-pointer" v-for=" label  in  emailLabel " :key=" label.title + $route.path "
                :active=" getPaginateRequest.FT === label.value " :disabled=" boiteMails === null && boiteMailsId === null " @click="isDynamicRouteActive(label, 'FT')">
                <span :class=" `bullet-${label.color}` " class="bullet bullet-sm mr-1" />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Syncro -->
            <h6 class="section-label mt-3 mb-1 px-2">Synchronisation e-mail</h6>
            <!-- boiteMails -->
            <b-list-group class="list-group-labels">
              <b-overlay :show=" show " rounded="sm" spinner-small spinner-type="grow" spinner-variant="primary">
                <b-list-group class="list-group-labels">
                  <b-list-group-item v-if=" boiteMails !== null && boiteMailsId !== null "
                    v-b-popover.hover.html=" `Cliquez sur <u><strong>${boiteMails}</strong></u> pour désynchroniser votre boîte de messagerie.` "
                    class="cursor-pointer" title="désynchroniser la boîte email" @click=" desyncBoiteMail ">
                    <span class="align-middle ml-1">{{ boiteMails }}</span>
                  </b-list-group-item>
                  <b-list-group-item v-else v-b-toggle.sync-mails class="cursor-pointer">
                    <feather-icon icon="DownloadCloudIcon" />
                    <span class="align-middle ml-1">Synchroniser votre email</span>
                  </b-list-group-item>
                </b-list-group>
              </b-overlay>
            </b-list-group>
            <div class="form-group-compose text-center compose-btn">

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                 variant="primary" @click="modalEmail()">
                 <span class="display-5">
                  <feather-icon  icon="PlusIcon" />
                 </span>
                <span class="display-5">Nouveau Modèle mails</span>
                
              </b-button>
            </div>
          </vue-perfect-scrollbar>


          <div class="form-group-compose text-center compose-btn">
             
            </div>
        </div>
      </div>
    </div>
    <synchronization-email-vue @boite-sync-returned=" boiteSyncReturned " />
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BBadge, BButton, BFormCheckbox, BListGroup, BListGroupItem, BOverlay, VBPopover, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { Vue } from 'vue-property-decorator'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import SynchronizationEmailVue from './SynchronizationEmail.vue'

export default {
  directives: {
    Ripple,
    'b-popover': VBPopover,
    'b-toggle': VBToggle
  },
  components: {
    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // BDropdown,
    // BDropdownItem,

    // 3rd Party
    VuePerfectScrollbar,
    SynchronizationEmailVue,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true
    },
    emailsMeta: {
      type: Object,
      required: true
    }
  },
  computed: {
    getPaginateRequest() {
      return this.$store.getters['messageries/getPaginateRequest'];
    }
  },
  methods: {
    isDynamicRouteActive(filter, type) {
      switch (type) {
        case 'FD':
          this.$store.commit('messageries/setPaginateRequestFD', filter)
          break;
        case 'FT':
          this.$store.commit('messageries/setPaginateRequestFT', filter)
          break;
      }
      this.$emit('close-left-sidebar')
      this.$emit('fetch-emails', false)
    },
     modalEmail() {
      this.$router.push({
        name: 'bibliotheque-mails',
        params:{showAddModel:true}
      })

    },
  },
  setup(props, context) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60
    }

    const boiteMails = ref(null)
    const show = ref(false)
    const boiteMailsId = ref(null)
    const selectedCheck = ref(true)

    const boiteSyncReturned = (data, text) => {
      show.value = true
      /*      Vue.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Succès',
          icon: 'BellIcon',
          text,
          variant: 'success'
        }
      }) */
      boiteMails.value = data.email
      boiteMailsId.value = data.id
      selectedCheck.value = true
      show.value = false
      context.emit('is-email-synchronized', 'synchronized')
      Vue.swal({
        title: text,
        text: 'La synchronisation de votre boite mail sur les 7 derniers jours est en cours. Cela peut prendre un peu de temps en fonction de sa taille.  Vous recevrez un message de confirmation lorsque celle-ci sera terminée.',
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    }
    const desyncBoiteMail = () => {
      Vue.swal({
        title: '',
        text: 'Êtes-vous sûr de vouloir désynchroniser votre boîte ?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Non',
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          context.parent.$http
            .delete(`boite_mail/delete_account/${boiteMailsId.value}`)
            .then(res => {
              if (res.data.success) {
                boiteMails.value = null
                boiteMailsId.value = null
                selectedCheck.value = false
                Vue.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Succès',
                    icon: 'BellIcon',
                    text: res.data.message,
                    variant: 'success'
                  }
                })
              } else {
                selectedCheck.value = true
                Vue.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Erreur',
                    icon: 'BellIcon',
                    text: "Votre email n'est pas désynchronisé.",
                    variant: 'danger'
                  }
                })
              }
            })
            .catch(err => {
              selectedCheck.value = true
              Vue.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Erreur',
                  icon: 'BellIcon',
                  text: "Votre email n'est pas désynchronisé.",
                  variant: 'danger'
                }
              })
              console.error(err)
            })
            .finally(() => {
              context.emit('is-email-synchronized', 'desynchronized')
              selectedCheck.value = true
            })
        }
      })
    }

    const userHasBoiteEmailSync = () => {
      show.value = true
      store
        .dispatch('messageries/userHasBoiteEmailSync')
        .then(response => {
          if (response.data.success) {
            boiteMails.value = response.data.email
            boiteMailsId.value = response.data.id
            selectedCheck.value = true
            show.value = false
          } else {
            selectedCheck.value = false
            boiteMails.value = null
            boiteMailsId.value = null
            show.value = false
          }
        })
        .catch(err => {
          selectedCheck.value = false
          boiteMails.value = null
          boiteMailsId.value = null
          show.value = false
          console.error(err)
        })
    }

    userHasBoiteEmailSync()

    const emailFilters = [
      {
        title: 'Boîte de réception',
        value: 'R',
        icon: 'MailIcon',
        route: { name: 'messageries' }
      },
      {
        title: 'Messages envoyés',
        value: 'S',
        icon: 'SendIcon',
        route: {
          name: 'messageries-folder',
          params: { folder: 'sent' }
        }
      }
      // { title: 'Messages suivis', icon: 'StarIcon', route: { name: 'messageries-folder', params: { folder: 'starred' } } }

      // { title: 'Tous les événements', icon: 'ListIcon', route: { name: 'messageries' } },
      // { title: 'Message', icon: 'MailIcon', route: { name: 'messageries-folder', params: { folder: 'Message' } } },
      // { title: 'Tâches', icon: 'UserCheckIcon', route: { name: 'messageries-folder', params: { folder: 'Tache' } } },
      // { title: 'Échéance', icon: 'ClockIcon', route: { name: 'messageries-folder', params: { folder: 'echeance' } } },
      // { title: 'Rendez-vous', icon: 'CalendarIcon', route: { name: 'messageries-folder', params: { folder: 'RendezVous' } } },
      // { title: 'Rappel', icon: 'BellIcon', route: { name: 'messageries-folder', params: { folder: 'Rappel' } } },
      // { title: 'Notes', icon: 'Edit2Icon', route: { name: 'messageries-folder', params: { folder: 'Notes' } } }
    ]

    const emailLabel = [
      // { title: 'Interne', color: 'success', route: { name: 'messageries-label', params: { label: 'Interne' } } },
      // { title: 'Externe', color: 'primary', route: { name: 'messageries-label', params: { label: 'Externe' } } },
      {
        title: 'Réseaux',
        value: 'RE',
        color: 'warning',
        route: {
          name: 'messageries-label',
          params: { label: 'Reseaux' }
        }
      },
      {
        title: 'Assureurs',
        value: 'AS',
        color: 'info',
        route: {
          name: 'messageries-label',
          params: { label: 'Assureurs' }
        }
      },
      {
        title: 'Clients',
        value: 'CL',
        color: 'secondary',
        route: {
          name: 'messageries-label',
          params: { label: 'Clients' }
        }
      },
      {
        title: 'Comptabilité',
        value: 'CO',
        color: 'danger',
        route: {
          name: 'messageries-label',
          params: { label: 'Comptabilité' }
        }
      }
    ]

    const resolveFilterBadgeColor = filter => {
      if (filter === 'Draft') return 'light-warning'
      if (filter === 'Spam') return 'light-danger'
      return 'light-primary'
    }

    return {
      // UI
      perfectScrollbarSettings,
      resolveFilterBadgeColor,
      boiteMails,
      boiteMailsId,
      selectedCheck,

      // Filter & Labels
      emailFilters,
      emailLabel,
      show,

      // actions
      boiteSyncReturned,
      desyncBoiteMail
    }
  }
}
</script>

<style></style>
