const EmailAssureurs = [
    {
        email: 'santecourtage@harmonie-mutuelle.fr',
        name: 'HARMONIE MUTUELLE - Service Santé Courtage',
        id_client: null,
        user_id: null,
        type_client: null,
        courtier_user_id: null,
        type_personne: 'Autre'
    },
    {
        email: 'comptabilite.recouvrement.clients@swisslife.fr',
        name: 'SWISSLIFE - Gestion des comptes de vos clients',
        id_client: null,
        user_id: null,
        type_client: null,
        courtier_user_id: null,
        type_personne: 'Autre'
    },
    {
        email: 'accueil.intermediaires@swisslife.fr',
        name: 'SWISSLIFE - Service clients',
        id_client: null,
        user_id: null,
        type_client: null,
        courtier_user_id: null,
        type_personne: 'Autre'
    },
    {
        email: 'devis@fma.fr',
        name: 'FMA - Contacts IARD - Concernant un devis',
        id_client: null,
        user_id: null,
        type_client: null,
        courtier_user_id: null,
        type_personne: 'Autre'
    },
    {
        email: 'gestion@fma.frr',
        name: 'FMA - Contacts IARD - Concernant un contrat',
        id_client: null,
        user_id: null,
        type_client: null,
        courtier_user_id: null,
        type_personne: 'Autre'
    },
    {
        email: 'c.bouaziz@eca-assurances.com',
        name: 'ECA - Contact commercial',
        id_client: null,
        user_id: null,
        type_client: null,
        courtier_user_id: null,
        type_personne: 'Autre'
    },
    {
        email: 'relationsclients@eca-assurances.com',
        name: 'ECA - Contact relation client (assuré)',
        id_client: null,
        user_id: null,
        type_client: null,
        courtier_user_id: null,
        type_personne: 'Autre'
    },
    {
        email: 'gestionsante@eca-assurances.com',
        name: 'ECA - Contact gestion courtier',
        id_client: null,
        user_id: null,
        type_client: null,
        courtier_user_id: null,
        type_personne: 'Autre'
    },
]
export default EmailAssureurs;