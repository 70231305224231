<template>
  <div>
    <b-modal id="compose-mail" :visible="shallShowEmailComposeModal" body-class="p-0"
             footer-class="d-flex justify-content-between"
             hide-backdrop modal-class="modal-sticky" no-fade size="lg" static title="Compose Email"
             @change="val => $emit('update:shall-show-email-compose-modal', val)"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">{{
            typeAction ? optionsTypeAction.find(item => item.value === typeAction).text : optionsEvenement.find(item => item.value === typeEvenement).text
          }}</h5>
        <div class="modal-actions">
          <feather-icon :disabled="showLoading" class="cursor-pointer" icon="MinusIcon"
                        @click="$emit('update:shall-show-email-compose-modal', false)"
          />
          <!-- <feather-icon icon="Maximize2Icon" class="ml-1 cursor-pointer" /> -->
          <feather-icon :disabled="showLoading" class="ml-1 cursor-pointer" icon="XIcon" @click="discardEmail"/>
        </div>
      </template>
 
      <!-- Modal Footer -->
      <template #modal-footer>
        <!-- Footer: Left Content -->
        <div>
          <!-- <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" split text="Envoyer" variant="primary" right @click="sendEmail"> -->
          <!-- <b-dropdown-item> Envoi de calendrier </b-dropdown-item> -->
          <!-- </b-dropdown> -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="showLoading" right variant="primary"
                    @click="sendEmail"
          > Envoyer
          </b-button>

          <!-- <feather-icon icon="PaperclipIcon" size="17" class="ml-2 cursor-pointer" /> -->

          <b-dropdown id="dropdown-grouped" v-ripple.400="'rgba(186, 191, 199, 0.15)'" :disabled="showLoading"
                      class="dropdown-icon-wrapper ml-50"
                      right variant="flat-secondary"
          >
            <template #button-content>
              <feather-icon class="align-middle" icon="PaperclipIcon" size="16"/>
            </template>

            <b-dropdown-item v-b-modal.modal-upload-from-pc>
              <feather-icon class="mr-50" icon="MonitorIcon" size="18"/>
              <span class="align-middle">Parcourir cet ordinateur</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.modal-upload-from-ged>
              <feather-icon class="mr-50" icon="CloudIcon" size="18"/>
              <span class="align-middle">Parcourir la GED</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item v-else @click="infoText('Information', 'Veuillez choisir le client avant de naviguer sur la GED.')">
            <feather-icon icon="CloudIcon" class="mr-50" size="18" />
            <span class="align-middle">Parcourir la GED</span>
          </b-dropdown-item> -->
          </b-dropdown>
        </div>
        <modal-upload-from-ged ref="upload-from-ged" :compose-tier="composeData.tiers ? composeData.tiers : null"
                               @documents-Selected="documentsSelected"
        />
        <modal-upload-from-pc ref="modal-upload-from-pc" @documents-Selected-file="documentsSelectedFile"/>

        <!-- Footer: Right Content -->
        <div>
          <!-- Dropdown: More Actions -->
          <!-- <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="17"
              class="text-body"
            />
          </template>

          <b-dropdown-item>
            Ajouter une étiquette
          </b-dropdown-item>

          <b-dropdown-item>
            Mode texte brut
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item>
            Imprimer
          </b-dropdown-item>
          <b-dropdown-item>
            Check Spelling
          </b-dropdown-item>
        </b-dropdown> -->

          <!-- Discard Compose -->
          <!-- <feather-icon icon="TrashIcon" size="17" class="ml-75 cursor-pointer" @click="discardEmail" /> -->
        </div>
      </template>

      <!-- Modal: Body -->
      <validation-observer ref="emailComposeRules">
        <b-form>

          <b-overlay :show="showLoading" no-wrap/>

          <!-- Field: To required|email -->
          <validation-provider #default="{ errors }"
                               :rules="fieldsVisibility.includes('to') && typeEvenement !== 'Message' ? 'required' : '' "
                               name="destinataire"
          >
            <div v-if="fieldsVisibility.includes('to')" :state="errors.length > 0 ? false : null"
                 class="compose-mail-form-field"
            >
              <label class="form-label" for="email-to">À : </label>
              <v-select v-model="composeData.to" :close-on-select="false"
                        :create-option="email => ({ email: email, name: email })"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :filter="filterByEmailOrName"
                        :options="emailToOptions" :reduce="to => `${to.email}/${to.id_client || to.user_id}${to.categorie_client ? '/' + to.categorie_client : ''}`"
                        class="flex-grow-1 email-to-selector" input-id="email-to" label="email"
                        multiple push-tags taggable
                        @input="affectTiersFromTo"
              >
                <template #option="{ avatar, name, type_personne, email }">
                  <b-avatar :src="avatar" size="sm"/>
                  <span class="ml-50"> {{ name + ' <' + (typeEvenement === 'Message' ? email : name) + '>' }} </span>
                </template>
                <div slot="no-options">Aucune e-mail disponible.</div>
              </v-select>
              <span v-if="fieldsVisibility.includes('cc')" class="cursor-pointer pr-1"
                    @click="showCcField = !showCcField"
              >Cc</span>
              <span v-if="fieldsVisibility.includes('cci')" class="cursor-pointer pr-1"
                    @click="showCciField = !showCciField"
              >Cci</span>
              <!--              <span class="cursor-pointer" @click="AfftectedListCollabTo()">Collaborateur</span>-->
            </div>
          </validation-provider>


           <validation-provider #default="{ errors }"
                       :rules="fieldsVisibility.includes('cci') && typeEvenement !== 'Message' ? 'required' : ''"
                       name="cci"
  >
              <div v-if="fieldsVisibility.includes('cci')" v-show="showCciField" :state="errors.length > 0 ? false : null"
                   class="compose-mail-form-field"
              >
                <label class="form-label" for="email-to">Cci : </label>
                <v-select v-model="composeData.cci" :close-on-select="false"
                          :create-option="email => ({ email: email, name: email })"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :filter="filterByEmailOrName"
                          :options="emailToOptions" :reduce="to => `${to.email}/${to.id_client || to.user_id}${to.categorie_client ? '/' + to.categorie_client : ''}`"
                          class="flex-grow-1 email-to-selector" input-id="email-to" label="email"
                          multiple push-tags taggable
                >
                  <template #option="{ avatar, name, type_personne, email }">
                    <b-avatar :src="avatar" size="sm"/>
                    <span class="ml-50"> {{ name + ' <' + (typeEvenement === 'Message' ? email : name) + '>' }} </span>
                  </template>
                  <div slot="no-options">Aucune e-mail disponible.</div>
                </v-select>
         
                <!--              <span class="cursor-pointer" @click="AfftectedListCollabTo()">Collaborateur</span>-->
              </div>
            </validation-provider>

          <!-- Field: Cc (Hidden Initially) -->
          <div v-if="fieldsVisibility.includes('cc')" v-show="showCcField" class="compose-mail-form-field">
            <label for="email-cc">Cc : </label>
            <v-select v-model="composeData.cc" :close-on-select="false"
                      :create-option="email => ({ email: email, name: email })"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :filter="filterByEmailOrName"
                      :options="emailToOptions" :reduce="to => `${to.email}/${to.id_client || to.user_id}${to.categorie_client ? '/' + to.categorie_client : ''}`"
                      class="flex-grow-1 email-cc-selector" filterable input-id="email-cc" label="email"
                      multiple push-tags taggable
            >
              <template #option="{ avatar, name, type_personne, email }">
                <b-avatar :src="avatar" size="sm"/>
                <span class="ml-50"> {{ name + ' <' + (typeEvenement === 'Message' ? email : name) + '>' }} </span>
                <!-- <b-badge class="mr-2 float-right" :variant="type_personne === 'Client' ? 'light-danger' : 'light-success'"> {{ type_personne }} </b-badge> -->
              </template>

              <!--              <template #selected-option="{ avatar, email }">-->
              <!--                <b-avatar size="sm" class="border border-white" :src="avatar" />-->
              <!--                <span class="ml-50"> {{ email }}</span>-->
              <!--              </template>-->
              <div slot="no-options">Aucune e-mail disponible.</div>
            </v-select>
          </div>

          <!-- Field: Cci (Hidden Initially) -->
          <!-- <div v-if="fieldsVisibility.includes('cci')" v-show="showCciField" class="compose-mail-form-field">
            <label for="email-cci">Cci : </label>
            <v-select v-model="composeData.cci" :close-on-select="false"
                      :create-option="email => ({ email: email, name: email })"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :filter="filterByEmailOrName"
                      :options="emailToOptions" :reduce="cci => cci.email"
                      class="flex-grow-1 email-cc-selector" filterable input-id="email-cci" label="email"
                      multiple push-tags taggable
            >
              <template #option="{ avatar, name, type_personne, email }">
                <b-avatar :src="avatar" size="sm"/>
                <span class="ml-50"> {{ name + ' <' + (typeEvenement === 'Message' ? email : name) + '>' }} </span>
              </template>

          
              <div slot="no-options">Aucune e-mail disponible.</div>
            </v-select>
          </div> -->


          <!-- Field: model -->
          <div v-if="fieldsVisibility.includes('model')" class="compose-mail-form-field">
            <label for="email-model" style="width: 75px;">Modèle : </label>
            <!-- <b-form-input id="email-model" disabled /> -->
            <b-form-select v-if="fieldsVisibility.includes('model')" v-model="modelsSelected" class="form-control"
                         
            >
              <template #first>
                <b-form-select-option  :value="{}">-- Choisissez --</b-form-select-option>
              </template>
              <b-form-select-option v-for="(model, i) in modelsOptions" :key="i" :value="model">{{ model.modele }}</b-form-select-option>
            </b-form-select>
            <!-- <b-form-select text-field="objet" @change="modelsSelectedMethod($event)" :options="modelsOptions" size="sm" class="form-control" v-if="fieldsVisibility.includes('model')" /> -->
          </div>

          <!-- Field: Subject -->
          <validation-provider #default="{ errors }" :rules="fieldsVisibility.includes('subject') ? 'required' : ''"
                               name="objet"
          >
            <div v-if="fieldsVisibility.includes('subject')" :state="errors.length > 0 ? false : null"
                 class="compose-mail-form-field"
            >
              <label for="email-subject" style="width: 75px;">Objet : </label>
              <b-form-input id="email-subject" v-model="composeData.subject"/>
            </div>
          </validation-provider>

          <!-- Field: Date -->
          <div v-if="fieldsVisibility.includes('dateDebut') || fieldsVisibility.includes('dateFin')">
            <b-row class="compose-mail-form-field">
              <b-col :v-if="fieldsVisibility.includes('dateDebut')" class="pr-0">
                <validation-provider #default="{ errors }"
                                     :name="typeEvenement === 'Echeance' ? 'date échéance' : 'date début'"
                                     :rules="fieldsVisibility.includes('dateDebut') ? 'required' : ''"
                >
                  <div :state="errors.length > 0 ? false : null" class="compose-mail-form-field p-0 border-0">
                    <label :state="errors.length > 0 ? false : null" for="email-date-debut">Date : </label>
                    <flat-pickr id="email-date-debut" v-model="composeData.dateDebut" :config="configDate"
                                class="form-control mr-1"
                                style="border-right: 1px solid rgba(34, 41, 47, 0.05); border-radius: 0px"
                    />
                  </div>
                </validation-provider>
              </b-col>
              <b-col v-if="fieldsVisibility.includes('dateFin')" class="p-0">
                <validation-provider #default="{ errors }"
                                     :rules="fieldsVisibility.includes('dateFin') ? 'required' : ''"
                                     name="date fin"
                >
                  <div :state="errors.length > 0 ? false : null" class="compose-mail-form-field p-0 border-0">
                    <label for="email-date-fin">À : </label>
                    <flat-pickr id="email-date-fin" v-model="composeData.dateFin" :config="configDate"
                                class="form-control"
                    />
                  </div>
                </validation-provider>
              </b-col>
            </b-row>
          </div>

          <!-- Field: Tiers -->
          <!--          <div v-if="fieldsVisibility.includes('tiers')" class="compose-mail-form-field">
                      <label for="email-tier">Clients : </label>
                      <v-select v-if="renderComponent" v-model="composeData.tiers"
                                :close-on-select="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tiersOptions" class="flex-grow-1" input-id="tier" label="name" @input="fetchContratSinistreDevisReclamationByTier"
                      >
                        <template #option="{ avatar, name, type_client, categorie_client }">
                          <b-avatar :src="avatar" size="sm"/>
                          <span class="ml-50"> {{ name }}</span>
                          <b-badge :variant="categorieVariant(categorie_client)" class="mr-2 float-right">
                            {{ capitalizeFirstLetter(categorie_client) }}
                          </b-badge>
                        </template>

                        <template #selected-option="{ avatar, name }">
                          <b-avatar :src="avatar" class="border border-white" size="sm"/>
                          <span class="ml-50"> {{ name }}</span>
                        </template>
                        <div slot="no-options">Aucune client disponible.</div>
                      </v-select>
                    </div>-->

          <!-- Field Selects Options: Contrat - Sinistre - Devis - Reclamation -->
          <!--          <div class="compose-mail-form-field">
                      <b-form-select v-if="fieldsVisibility.includes('tiers') && fieldsVisibility.includes('contrat')"
                                     v-model="composeData.contrat_id"
                                     :options="optionsContratShow" class="form-control" size="sm"
                                     @change="filterOptionsFormSelect('contrat', composeData.contrat_id)"
                      />
                      <b-form-select v-if="fieldsVisibility.includes('tiers') && fieldsVisibility.includes('sinistre')"
                                     v-model="composeData.sinistre_id"
                                     :options="optionsSinistreShow" class="form-control" size="sm"
                                     @change="filterOptionsFormSelect('sinistre', composeData.sinistre_id)"
                      />
                      <b-form-select v-if="fieldsVisibility.includes('tiers') && fieldsVisibility.includes('devis')" v-model="composeData.devis_id" :options="optionsDevisShow" class="form-control"
                                     size="sm"
                      />
                      <b-form-select v-if="fieldsVisibility.includes('tiers') && fieldsVisibility.includes('reclamation')"
                                     v-model="composeData.reclamation_id"
                                     :options="optionsReclamationShow" class="form-control" size="sm"
                                     @change="filterOptionsFormSelect('reclamation', composeData.reclamation_id)"
                      />
                    </div>-->

          <!-- Field: Message - Quill Editor -->
          <validation-provider #default="{ errors }" :rules="fieldsVisibility.includes('message') ? 'required' : ''"
                               name="message"
          >
            <div v-if="fieldsVisibility.includes('message')" :state="errors.length > 0 ? false : null"
                 class="message-editor"
            >
              <quill-editor id="quil-content" v-model="composeData.message" :options="editorOption"
                            style="max-height: 30vh; overflow: auto"
              />
              <div id="quill-toolbar" class="d-flex border-bottom-0">
                <!-- Add a bold button -->
                <b-row>
                  <b-col lg="8">
                    <button class="ql-bold"/>
                    <button class="ql-italic"/>
                    <select class="ql-align">
                      <option value=""/>
                      <option value="right">Droite</option>
                      <option value="center">Centre</option>
                      <option value="justify">Justifier</option>
                    </select>
                    <select class="ql-color">
                      <option v-for="(color, index) in colors" :key="index" :value="color.value"/>
                    </select>
                    <select class="ql-background">
                      <option v-for="(color, index) in colors" :key="index" :value="color.value"/>
                    </select>
                    <button class="ql-blockquote"/>
                    <button class="ql-code-block"/>
                    <button class="ql-list" value="ordered"/>
                    <button class="ql-list" value="bullet"/>
                    <button class="ql-script" value="sub"/>
                    <button class="ql-script" value="super"/>
                    <button class="ql-link"/>
                    <button class="ql-image"/>
                    <button class="ql-video"/>
                    <button class="ql-clean"/>
                  </b-col>
                  <b-col lg="4">
                    <select class="ql-header">
                      <option value="1">H1</option>
                      <option value="2">H2</option>
                      <option value="3">H3</option>
                      <option value="4">H4</option>
                      <option value="5">H5</option>
                      <option value="6">H6</option>
                      <option selected value="false">Normale</option>
                    </select>
                    <select class="ql-size">
                      <option value="small">Petite</option>
                      <option selected>Moyenne</option>
                      <option value="large">Grande</option>
                      <option value="huge">Trés grande</option>
                    </select>
                  </b-col>
                </b-row>
              </div>
            </div>
          </validation-provider>

          <!-- Data Document attached -->
          <div v-if="composeData.attached && composeData.attached.length > 0" class="border-top border-bottom p-1"
               style="max-height: 9vh; overflow: auto"
          >
            <b-button-group v-for="(file, index) in composeData.attached" :key="index" size="sm">
              <b-button v-b-popover.hover="file.document_name + ' (' + file.document_taille + ')'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-secondary"
                        @click="displayDocument(file.document_id, file.document_file_name, file.document_name)"
              >
                {{
                  file.document_name.slice(0, 15)
                      .concat('...')
                }}
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" variant="outline-secondary"
                        @click="detachOrDeleteFile('attached', 'detach', index)"
              >
                <feather-icon icon="XIcon"/>
              </b-button>
            </b-button-group>
          </div>
          <div v-if="composeData.newFiles && composeData.newFiles.length > 0" class="border-top border-bottom p-1"
               style="max-height: 9vh; overflow: auto"
          >
            <b-button-group v-for="(file, index) in composeData.newFiles" :key="index" size="sm">
              <b-button v-b-popover.hover="file.nom" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-secondary" @click="downloadDocumentCurrentUploaded(file.file)"
              > {{
                  file.nom.slice(0, 15)
                      .concat('...')
                }}
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" variant="outline-secondary"
                        @click="detachOrDeleteFile('newFiles', 'detach', index)"
              >
                <feather-icon icon="XIcon"/>
              </b-button>
            </b-button-group>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal id="modal-display-document-composer" ref="modal-display-document-composer" :title="document.name"
             cancel-title="Fermer" cancel-variant="outline-secondary" ok-title="Télécharger"
             @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form ref="form">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BButtonGroup,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BOverlay,
  BRow,
  VBPopover
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import store from '@/store'
import { Vue } from 'vue-property-decorator'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import colorPicker from './ColorPicker'
import modalUploadFromGed from './UploadDocumentFromGED.vue'
import modalUploadFromPc from './UploadDocumentFromPC.vue'
import EmailAssureurs from "@/shared/constants/contactEmailAssureurs"


export default {
  directives: {
    'b-popover': VBPopover,
    Ripple
  },
  components: {
    // Validate
    ValidationProvider,
    ValidationObserver,

    // BSV
    BOverlay,
    BDropdown,
    BImg,
    BFormSelectOption,
    BDropdownItem,
    BButton,
    BButtonGroup,
    BForm,
    BFormInput,
    BAvatar,
    BBadge,
    BFormSelect,
    BRow,
    BCol,
    EmailAssureurs,

    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
    // BDropdownDivider,
    flatPickr,

    // 3rd Party
    quillEditor,
    vSelect,

    // App
    // eslint-disable-next-line vue/no-unused-components
    modalUploadFromGed,
    modalUploadFromPc,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal'
  },
  props: {
    emailToSend: {
      // type: String,
      default: null,
      required: false
    },
    copierCarbonToSend: {
      // type: String,
      default: null,
      required: false
    },
    typeAction: {
      type: String,
      default: null,
      required: false
    },
    emailViewData: {
      type: Object,
      default: null,
      required: false
    },
    typeEvenement: {
      type: String,
      default: 'Message',
      required: true
    },
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true
    },
    emailsContacts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      renderComponent: true,
      required,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      }
    }
  },
  created() {
    this.composeData.to = []
    this.composeData.cci = []
    this.composeData.tiers = null
  },
  mounted() {
    if (this.emailToSend) {
      if (Array.isArray(this.emailToSend)) {
        if (this.emailToSend && this.copierCarbonToSend) {
          switch (this.copierCarbonToSend) {
            case 'To':
              this.composeData.to = []
              this.emailToSend.forEach(el => {
                this.composeData.to.push(el)
              })
              break
            case 'Cci':
              this.composeData.cci = []
              this.emailToSend.forEach(el => {
                this.composeData.cci.push(el)
              })
              this.showCciField = !this.showCciField
              break
          }
        }
        // if(Array.isArray(this.emailToSend))
        // {
        //   this.composeData.cci = []
        //   for(let i=0;i<this.emailToSend.length;i++){
        //     this.composeData.cci.push(this.emailToSend[i])
        //   }
        //   this.showCciField = !this.showCciField
        // }
        // else
        // {
        //   this.composeData.to.push(this.emailToSend)
        // }
      } else {
        this.composeData.to.push(this.emailToSend)
      }
    }

  },
  watch: {
    typeAction: {
      deep: true,
      immediate: true,
      handler(val) {
        this.fetchDataReply(val)
      }
    },
    // "composeData.message":{
    //   deep: true,
    //   immediate:true,
    //   handler(val){
    //     console.log('1')
    //     if(this.composeData.message=== undefined && this.composeData.subject === undefined){
          
    //       this.composeData.message = `<br><blockquote>${this.emailViewData.message}</blockquote>`
    //     }
    //     else{

    //       this.composeData.message += `<br><blockquote>${this.emailViewData.message}</blockquote>`
    //     }
    //   }
    // },
    emailViewData: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchDataReply(this.typeAction)
      }
    },
    tiersOptions: {
      deep: true,
      immediate: true,
      handler() {
        if (this.emailToSend) {
          this.affectTiersFromTo([this.emailToSend])
        }
      }
    }
  },
  computed: {
    categorieVariant() {
      const categorieColor = {
        /* eslint-disable key-spacing */
        PARTICULIER: 'light-danger',
        PROFESSIONNEL: 'light-warning',
        ENTREPRISE: 'light-success',
        /* eslint-enable key-spacing */
      }

      return categories => categorieColor[categories]
    },
  },
  methods: {
    // AfftectedListCollabTo(){
    //   this.emailsContacts.filter(item => item.type_personne === 'Utilisateur').forEach(el => {
    //     if (!this.composeData.to.includes(el.email)) {
    //       this.composeData.to.push(el.email)
    //     }
    //   })
    // },

    affectTiersFromTo(e) {
      if (e.length > 0) {
        // this.composeData.tiers = null
        this.forceRerender()
        // if ((this.composeData.hasOwnProperty('tiers') && !this.composeData.tiers) || !this.composeData.hasOwnProperty('tiers')) {
        e.forEach(el => {
          const clientSelected = this.tiersOptions.find(item => item.email === el && item.type_personne === 'Client')
          // console.log(this.tiersOptions)
          //   console.log('clientSelected', clientSelected)
          if (clientSelected) {
            this.composeData.tiers = clientSelected
            return 0
          }
        })
        // }
      }
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },
    filterByEmailOrName(options, search) {
      return search.length
          ? this.emailToOptions.filter(item => item.name.includes(search) || item.email.includes(search))
          : this.emailToOptions
    },
    documentsSelectedFile(files) {
      const dataAttached = []
        if (!this.composeData.newFiles) {
         Vue.set(this.composeData, 'newFiles', dataAttached)
       } 
      files.forEach(item => {
        this.composeData.newFiles.push({
          description: item.description,
          file: item.file,
          nom: item.nom,
          idFamille: item.idFamille,
          idType: item.idType
        })
      })

      this.$refs['modal-upload-from-pc'].hideModal()
    },
    documentsSelected(documentsItems, documentSearch) {
      if (documentsItems.selectedRows && documentsItems.selectedRows.length > 0) {
        let dataAttached = []
        if (this.composeData.attached) {
          dataAttached = this.composeData.attached
        }

        documentsItems.selectedRows.forEach(item => {
          if (documentSearch === 'Clients') {
            dataAttached.push({
              document_id: item.id,
              document_name: item.NomDuDocument,
              document_taille: item.Taille,
              document_path: item.Path,
              document_file_name: item.Name,
              emplacement_document: documentSearch,
              client_id: item.clientId,
              client_type: item.clientType
            })
          } else if (documentSearch === 'Courtier') {
            dataAttached.push({
              document_id: item.id,
              document_name: item.NomDuDocument,
              document_taille: item.Taille,
              document_path: item.Path,
              document_file_name: item.Name,
              emplacement_document: documentSearch
            })
          }
        })
        Vue.set(this.composeData, 'attached', dataAttached)
        this.$refs['upload-from-ged'].hideModal()
      }
    },
    documentsSelectedFromTR(documentsItems, documentSearch) {
      if (documentsItems.selectedRows && documentsItems.selectedRows.length > 0) {
        let dataAttached = []
        if (this.composeData.attached) {
          dataAttached = this.composeData.attached
        }

        documentsItems.selectedRows.forEach(item => {
          if (documentSearch === 'Clients') {
            dataAttached.push({
              document_id: item.id,
              document_name: item.NomDuDocument,
              document_taille: item.Taille,
              document_path: item.Path,
              document_file_name: item.Name,
              emplacement_document: documentSearch,
              client_id: item.clientId,
              client_type: item.clientType
            })
          } else if (documentSearch === 'Courtier') {
            dataAttached.push({
              document_id: item.id,
              document_name: item.NomDuDocument,
              document_taille: item.Taille,
              document_path: item.Path,
              document_file_name: item.Name,
              emplacement_document: documentSearch
            })
          }
        })
        Vue.set(this.composeData, 'attached', dataAttached)
        this.$refs['upload-from-ged'].hideModal()
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    // eslint-disable-next-line consistent-return
    displayDocument(id, name, NomDuDocument) {
      if (!id) {
        this.messageToast('Aucun document trouvé pour l\'affichage ou le téléchargement.', 'Information', 'warning')
        return 0
      }
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (res.data.data.isDownload) {
                this.downloadDocument(res.data.data.base64, name)
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument

                this.$refs['modal-display-document-composer'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.downloadDocument(res.data.data.base64, name)
              }
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', 'error')
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    downloadURI(uri, name) {
      const link = document.createElement('a')
      link.download = name
      link.href = uri
      link.click()
    },
    downloadDocumentCurrentUploaded(file) {
      const url = window.URL.createObjectURL(file)
      const tab = window.open()
      tab.location.href = url
      // this.downloadURI(url, name)
      // window.URL.revokeObjectURL(url)
    },
    fetchDataReply(val) {
      this.composeData = {
        contrat_id: null,
        sinistre_id: null,
        devis_id: null,
        reclamation_id: null
      }
      switch (val) {
        case 'reply':
          // this.composeData.subject = `RE: ${this.emailViewData.subject}`
          // this.composeData.message = `Bonjour, <br><br><br><br>Cordialement.<br><br><blockquote>${this.emailViewData.message}</blockquote>`
          this.composeData.to = []

          if(this.emailViewData.type_user_mail==="from"){
            this.emailViewData.to.forEach(el => {
              const { email } = el
              this.composeData.to.push(email)
              this.emailToOptions.push({
                email,
                name: null,
                avatar: null,
                user_id: null,
                courtier_user_id: null,
                type_personne: 'Autre'
              })
            })
          }else if(this.emailViewData.type_user_mail==="to"){
            this.composeData.to.push(this.emailViewData.from.email)
            if(this.emailToOptions.findIndex(item => item.email === this.emailViewData.from.email) === -1){
              this.emailToOptions.push({
                email: this.emailViewData.from.email,
                name: this.emailViewData.from.email,
                avatar: null,
                user_id: null,
                courtier_user_id: null,
                type_personne: 'Autre'
              })
            }
          }

          break
        case 'replyAll':
          // this.composeData.subject = `RE: ${this.emailViewData.subject}`
          // this.composeData.message = `Bonjour, <br><br><br><br>Cordialement.<br><br><blockquote>${this.emailViewData.message}</blockquote>`
          this.composeData.to = []
          this.composeData.cc = []
          if(this.emailViewData.type_user_mail==="from"){
            this.emailViewData.to.forEach(el => {
              const { email } = el
              this.composeData.to.push(email)
              this.emailToOptions.push({
                email,
                name: null,
                avatar: null,
                user_id: null,
                courtier_user_id: null,
                type_personne: 'Autre'
              })
            })
          }else if(this.emailViewData.type_user_mail==="to"){
            this.composeData.to.push(this.emailViewData.from.email)
            if(this.emailToOptions.findIndex(item => item.email === this.emailViewData.from.email) === -1){
              this.emailToOptions.push({
                email: this.emailViewData.from.email,
                name: this.emailViewData.from.email,
                avatar: null,
                user_id: null,
                courtier_user_id: null,
                type_personne: 'Autre'
              })
            }
          }

          if (this.emailViewData.cc) {
            this.emailViewData.cc.forEach(el => {
              const { email } = el
              this.composeData.cc.push(email)
              this.emailToOptions.push({
                email,
                name: null,
                avatar: null,
                user_id: null,
                courtier_user_id: null,
                type_personne: 'Autre'
              })
            })
          }
          break
        case 'transfer':
          // this.composeData.subject = `TR: ${this.emailViewData.subject}`
          // this.composeData.message = `Bonjour, <br><br><br><br>Cordialement.<br><br><blockquote>${this.emailViewData.message}</blockquote>`
          break
        default:
          this.composeData = {
            contrat_id: null,
            sinistre_id: null,
            devis_id: null,
            reclamation_id: null
          }
          break
      }
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    }
  },
  setup(_, { emit }) {
    const composeData = ref({})
    const messageErreur = ref(null)
    const showLoading = ref(false)
    const emailComposeRules = ref(null)
    const base64File = ref(null)
    const configDate = ref({})
    const showCcField = ref(false)
    const showCciField = ref(false)
    const emailToOptions = ref([])
    const textEvenementSelected = ref()
    const optionsEvenement = ref([
      {
        text: 'Nouvelle tâche',
        shortText: 'La tâche',
        value: 'Tache'
      },
      {
        text: 'Nouveau Rendez-vous',
        shortText: 'Le rendez-vous',
        value: 'RendezVous'
      },
      {
        text: 'Nouvelle échéance',
        shortText: 'L\'échéance',
        value: 'Echeance'
      },
      {
        text: 'Nouveau rappel',
        shortText: 'le rappel',
        value: 'Rappel'
      },
      {
        text: 'Nouveau message',
        shortText: 'Le message',
        value: 'Message'
      }
    ])

    const optionsTypeAction = ref([
      {
        text: 'Répondre',
        shortText: 'Répondre',
        value: 'reply'
      },
      {
        text: 'Répondre à tous',
        shortText: 'Répondre à tous',
        value: 'replyAll'
      },
      {
        text: 'Transférer',
        shortText: 'Transférer',
        value: 'transfer'
      }
    ])
    const tiersOptions = ref([])
    const modelsOptions = ref([])
    const modelsSelected = ref({})
    const fieldsVisibility = ref(['to', 'cc', 'cci', 'subject', 'model', 'tiers', 'contrat', 'sinistre', 'devis', 'reclamation', 'message'])

    const optionsContrat = ref([{
      value: null,
      text: 'Contrat'
    }])
    const optionsSinistre = ref([{
      value: null,
      text: 'Sinistre'
    }])
    const optionsDevis = ref([{
      value: null,
      text: 'Devis'
    }])
    const optionsReclamation = ref([{
      value: null,
      text: 'Réclamation'
    }])

    const optionsContratShow = ref([{
      value: null,
      text: 'Contrat'
    }])
    const optionsSinistreShow = ref([{
      value: null,
      text: 'Sinistre'
    }])
    const optionsDevisShow = ref([{
      value: null,
      text: 'Devis'
    }])
    const optionsReclamationShow = ref([{
      value: null,
      text: 'Réclamation'
    }])

    composeData.value.contrat_id = null
    composeData.value.sinistre_id = null
    composeData.value.devis_id = null
    composeData.value.reclamation_id = null
    composeData.value.to = []

    const colors = ref([])
    colors.value = colorPicker.colors

    // const showBccField = ref(false)
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar'
      },
      placeholder: 'Message'
    }

    // Actions

    const showToast = (variant, timeout, title, text, icon) => {
      Vue.$toast(
          {
            component: ToastificationContentVue,
            props: {
              title,
              icon,
              text,
              variant
            }
          },
          { timeout }
      )
    }
    const discardEmail = () => {
      composeData.value = {}
      composeData.value.to = []
      modelsSelected.value = {}
      
      optionsContrat.value = [{
        value: null,
        text: 'Contrat'
      }]
      optionsSinistre.value = [{
        value: null,
        text: 'Sinistre'
      }]
      optionsDevis.value = [{
        value: null,
        text: 'Devis'
      }]
      optionsReclamation.value = [{
        value: null,
        text: 'Réclamation'
      }]

      optionsContratShow.value = [{
        value: null,
        text: 'Contrat'
      }]
      optionsSinistreShow.value = [{
        value: null,
        text: 'Sinistre'
      }]
      optionsDevisShow.value = [{
        value: null,
        text: 'Devis'
      }]
      optionsReclamationShow.value = [{
        value: null,
        text: 'Réclamation'
      }]

      // composeData.value.devis_id = null
      // composeData.value.contrat_id = null
      // composeData.value.sinistre_id = null
      // composeData.value.reclamation_id = null
      // composeData.value.to = []
      // composeData.value.message = null
      // composeData.value.subject = null

      // eslint-disable-next-line no-param-reassign
      // _.typeAction = null
      emit('update:shall-show-email-compose-modal', false)
    }
    const infoText = (title, textHtml, icon, confirmButton = 'btn btn-primary') => {
      Vue.swal({
        title,
        html: `<div class="align-left">${textHtml}</div>`,
        icon,
        customClass: {
          confirmButton
        },
        buttonsStyling: false
      })
    }
    const checkDestinataire = () => {
      let check = []

      if (showCciField.value) {
        if (!composeData.value.cci) {
          check.push([1])
        }
        else if (Array.isArray(composeData.value.cci)) {
          if (composeData.value.cci.length == 0)
            check.push([1])
        }
      }

      if (showCcField.value) {
        if (!composeData.value.cc) {
          check.push([2])
        }
        else if (Array.isArray(composeData.value.cc)) {
          if (composeData.value.cc.length == 0)
            check.push([2])
        }
      }

      if (composeData.value.to) {
        if (Array.isArray(composeData.value.to)) {
          if (composeData.value.to.length == 0)
            check.push([3])
        }
      }
      else {
        check.push([3])
      }

      if (check.length == 3 && (showCciField.value && showCcField.value)) {
        messageErreur.value = `<li>Un destinataire est obligatoire</li>`
        return true
      }
      else if (check.length == 2 && (showCciField.value && !showCcField.value)) {
        messageErreur.value = `<li>Un destinataire est obligatoire</li>`
        return true
      }
      else if (check.length == 2 && (!showCciField.value && showCcField.value)) {
        messageErreur.value = `<li>Un destinataire est obligatoire</li>`
        return true
      }
      else if (composeData.value.to.length == 0 && !showCciField.value && !showCcField.value) {
        messageErreur.value = `<li>Le champ destinataire est obligatoire</li>`
        return true
      }

      return false
      
    }
    const sendEmail = () => {
      // console.log(composeData.to.value)
      emailComposeRules.value.validate()
          .then(success => {
            if (!success) {
              
              let errors = '<ul>'
                if (checkDestinataire()) {
                  errors += messageErreur.value
                }
              fieldsVisibility.value.forEach(el => {


                 if (el === 'subject' && emailComposeRules.value.errors.objet.length > 0) {
                  errors += `<li>${emailComposeRules.value.errors.objet.join('\n')}</li>`
                } else if (el === 'message' && emailComposeRules.value.errors.message.length > 0) {
                  errors += `<li>${emailComposeRules.value.errors.message.join('\n')}</li>`
                } else if (el === 'dateDebut') {
                  if (emailComposeRules.value.errors['date échéance'] && emailComposeRules.value.errors['date échéance'].length > 0) {
                    errors += `<li>${emailComposeRules.value.errors['date échéance'].join('\n')}</li>`
                  } else if (emailComposeRules.value.errors['date début'] && emailComposeRules.value.errors['date début'].length > 0) {
                    errors += `<li>${emailComposeRules.value.errors['date début'].join('\n')}</li>`
                  }
                } else if (el === 'dateFin' && emailComposeRules.value.errors['date fin'].length > 0) {
                  errors += `<li>${emailComposeRules.value.errors['date fin'].join('\n')}</li>`
                }
              })
              
              errors += '</ul>'
              infoText('Erreur', errors, 'error', 'btn btn-danger')
            } else {
              let errors = '<ul>'
              if (checkDestinataire()) {
                errors += messageErreur.value
                infoText('Erreur', errors, 'error', 'btn btn-danger')
                return;
              }

              showLoading.value = true
              store
                  .dispatch('messageries/sendEmail', {
                    data: composeData.value,
                    type: _.typeEvenement
                  })
                  .then(response => {
                    if (response.data) {
                      if (response.data.success) {
                        emit('returned-email-saved', response.data.data.events_returned)
                        // composeData.value = []
                        emit('update:shall-show-email-compose-modal', false)
                        discardEmail()
                        showLoading.value = false
                        showToast('info', 3000, 'Messageries', 'Votre message a été envoyé.', 'InfoIcon')
                      } else {
                        showLoading.value = false
                        showToast('danger', 3000, 'Messageries', response.data.errors, 'InfoIcon')
                      }
                    }
                  })
                  .catch(err => {
                    showLoading.value = false
                    showToast('danger', 3000, 'Messageries', err.response.data.errors[0], 'InfoIcon')
                  })
            }
          })
    }

    const fetchModelsByCourtier = () => {
      store.dispatch('messageries/fetchModelsByCourtier')
          .then(response => {
            modelsOptions.value = response.data.data
          })
    }

    fetchModelsByCourtier()
    const filterOptionsFormSelect = (key, valueId) => {
      // eslint-disable-next-line camelcase
      if (valueId) {
        switch (key) {
          case 'contrat':
            if (valueId) {
              optionsSinistreShow.value = optionsSinistre.value.filter(item => item.contrat === valueId || item.value === null)
              optionsReclamationShow.value = optionsReclamation.value.filter(item => item.contrat === valueId || item.value === null)
            } else {
              optionsContratShow.value = optionsContrat.value
              optionsSinistreShow.value = optionsSinistre.value
              optionsReclamationShow.value = optionsReclamation.value
            }
            break

          case 'sinistre':
            // fetch list sinistre by id from optionsSinistre
            let sinistreFindById = optionsSinistre.value.find(item => item.value === valueId)

            optionsContratShow.value = optionsContrat.value.filter(item => item.value === sinistreFindById.contrat || item.value === null)
            composeData.value.contrat_id = sinistreFindById.contrat
            optionsReclamationShow.value = optionsReclamation.value.filter(item => item.sinistre === valueId || item.value === null)
            break
          case 'reclamation':
            if (valueId) {
              // fetch list reclamation by id from optionsReclamation
              let reclamationFindById = optionsReclamation.value.find(item => item.value === valueId)

              optionsContratShow.value = optionsContrat.value.filter(item => item.value === reclamationFindById.contrat || item.value === null)
              if (reclamationFindById.contrat) {
                composeData.value.contrat_id = reclamationFindById.contrat
              }

              optionsSinistreShow.value = optionsSinistre.value.filter(item => item.value === reclamationFindById.sinistre || item.value === null)
              if (reclamationFindById.sinistre) {
                // fetch list sinistre by id from optionsSinistre
                let sinistreFindById = optionsSinistre.value.find(item => item.value === reclamationFindById.sinistre)

                optionsContratShow.value = optionsContrat.value.filter(item => item.value === sinistreFindById.contrat || item.value === null)

                composeData.value.contrat_id = sinistreFindById.contrat
                composeData.value.sinistre_id = reclamationFindById.sinistre
              }
            }
            break
          default:
            optionsContratShow.value = optionsContrat.value
            optionsSinistreShow.value = optionsSinistre.value
            optionsReclamationShow.value = optionsReclamation.value
            break
        }
      } else {
        switch (key) {
          case 'contrat':
            optionsContratShow.value = optionsContrat.value
            optionsSinistreShow.value = optionsSinistre.value
            optionsReclamationShow.value = optionsReclamation.value

            delete composeData.value.reclamation_id
            delete composeData.value.sinistre_id
            delete composeData.value.contrat_id
            break
          case 'sinistre':
            optionsSinistreShow.value = optionsSinistre.value
            optionsReclamationShow.value = optionsReclamation.value

            delete composeData.value.reclamation_id
            delete composeData.value.sinistre_id
            break

          default:
            break
        }
      }
    }
    const fetchContratSinistreDevisReclamationByTier = tierSelected => {
      if (tierSelected && tierSelected.id_client && tierSelected.type_client) {
        store
            .dispatch('messageries/fetchContratSinistreDevisReclamationByTier', {
              tier_id: tierSelected.id_client,
              tier_type: tierSelected.type_client
            })
            .then(response => {
              optionsContrat.value = [{
                value: null,
                text: 'Contrat'
              }]
              optionsSinistre.value = [{
                value: null,
                text: 'Sinistre'
              }]
              optionsDevis.value = [{
                value: null,
                text: 'Devis'
              }]
              optionsReclamation.value = [{
                value: null,
                text: 'Réclamation'
              }]
              response.data.options_contrats.forEach(element => {
                optionsContrat.value.push({
                  value: element.value,
                  text: element.text
                })
              })

              response.data.options_sinistres.forEach(element => {
                optionsSinistre.value.push({
                  value: element.value,
                  text: element.text,
                  contrat: element.contrat
                })
              })

              response.data.options_devis.forEach(element => {
                optionsDevis.value.push({
                  value: element.value,
                  text: element.text
                })
              })

              response.data.options_reclamations.forEach(element => {
                optionsReclamation.value.push({
                  value: element.value,
                  text: element.text,
                  contrat: element.contrat,
                  sinistre: element.sinistre,
                  devis: element.devis
                })
              })
            })
            .finally(() => {
              optionsContratShow.value = optionsContrat.value
              optionsSinistreShow.value = optionsSinistre.value
              optionsDevisShow.value = optionsDevis.value
              optionsReclamationShow.value = optionsReclamation.value

              composeData.value.contrat_id = null
              composeData.value.sinistre_id = null
              composeData.value.devis_id = null
              composeData.value.reclamation_id = null
            })
      } else {
        optionsContrat.value = [{
          value: null,
          text: 'Contrat'
        }]
        optionsSinistre.value = [{
          value: null,
          text: 'Sinistre'
        }]
        optionsDevis.value = [{
          value: null,
          text: 'Devis'
        }]
        optionsReclamation.value = [{
          value: null,
          text: 'Réclamation'
        }]

        optionsContratShow.value = [{
          value: null,
          text: 'Contrat'
        }]
        optionsSinistreShow.value = [{
          value: null,
          text: 'Sinistre'
        }]
        optionsDevisShow.value = [{
          value: null,
          text: 'Devis'
        }]
        optionsReclamationShow.value = [{
          value: null,
          text: 'Réclamation'
        }]

        composeData.value.devis_id = null
        composeData.value.contrat_id = null
        composeData.value.sinistre_id = null
        composeData.value.reclamation_id = null
        delete composeData.value.tiers
      }
    }
    // const documentsSelected = (documentsItems, documentSearch) => {
    //   if (documentsItems.selectedRows && documentsItems.selectedRows.length > 0) {
    //     let dataAttached = []
    //     if (composeData.value.attached) {
    //       dataAttached = composeData.value.attached
    //     }

    //     documentsItems.selectedRows.forEach(item => {
    //       if (documentSearch === 'Clients') {
    //         dataAttached.push({
    //           document_id: item.id,
    //           document_name: item.NomDuDocument,
    //           document_taille: item.Taille,
    //           document_path: item.Path,
    //           document_file_name: item.Name,
    //           emplacement_document: documentSearch,
    //           client_id: item.clientId,
    //           client_type: item.clientType
    //         })
    //       } else if (documentSearch === 'Courtier') {
    //         dataAttached.push({
    //           document_id: item.id,
    //           document_name: item.NomDuDocument,
    //           document_taille: item.Taille,
    //           document_path: item.Path,
    //           document_file_name: item.Name,
    //           emplacement_document: documentSearch
    //         })
    //       }
    //     })
    //     Vue.set(composeData.value, 'attached', dataAttached)
    //     emit('modal-upload-from-ged')
    //   }
    // }
    // const documentsSelectedFile = files => {
    //   const dataAttached = []
    //   files.forEach(item => {
    //     dataAttached.push({
    //       description: item.description,
    //       file: item.file,
    //       nom: item.nom,
    //       idFamille: item.idFamille,
    //       idType: item.idType
    //     })
    //   })
    //   Vue.set(composeData.value, 'newFiles', dataAttached)
    // }
    const detachOrDeleteFile = (typeList, typeAction, index) => {
      if (typeAction === 'detach') {
        composeData.value[typeList].splice(index, 1)
      }
    }
    const modelsSelectedMethod = e => {
      // console.log(e)
    }
    // Fill emailToOption with emails clients and users by cabinet
    // watch(
    //   () => composeData.value,
    //   val => {
    //     if (val.to){
    //       console.log('klfjdshqjkldhsjklqhfjklqs')
    //     }
    //   },
    // { immediate: true },
    // { deep: true }
    // )
    // watch(
    //   () => _.composeData.value.tier,
    //   val => {
    //     console.log(_.composeData)
    //     fetchContratSinistreDevisReclamationByTier(val)
    //   }
    // )
    // emailToOptions.value = _.typeEvenement === 'Message' ? _.emailsContacts : _.emailsContacts.filter(item => item.type_personne === 'Utilisateur')
    watch(
        () => _.typeEvenement,
        val => {
          // let arrayFieldsVisible = []
          switch (val) {
            case 'Message':
              fieldsVisibility.value = ['to', 'cc', 'cci', 'subject', 'model', 'tiers', 'contrat', 'sinistre', 'devis', 'reclamation', 'message']
              configDate.value = { dateFormat: 'd/m/Y' }
              break

            case 'Tache':
              fieldsVisibility.value = ['to', 'cc', 'cci', 'subject', 'model', 'dateDebut', 'dateFin', 'tiers', 'contrat', 'sinistre', 'devis', 'reclamation', 'message']
              configDate.value = { dateFormat: 'd/m/Y' }
              break

            case 'RendezVous':
              fieldsVisibility.value = ['to', 'cc', 'cci', 'subject', 'model', 'dateDebut', 'dateFin', 'tiers', 'contrat', 'sinistre', 'devis', 'reclamation', 'message']
              configDate.value = {
                enableTime: true,
                dateFormat: 'd/m/Y H:i'
              }
              break

            case 'Echeance':
              fieldsVisibility.value = ['to', 'subject', 'model', 'dateDebut', 'tiers', 'contrat', 'sinistre', 'devis', 'reclamation', 'message']
              configDate.value = { dateFormat: 'd/m/Y' }
              break

            case 'Rappel':
              fieldsVisibility.value = ['to', 'cc', 'cci', 'subject', 'model', 'tiers', 'contrat', 'sinistre', 'devis', 'reclamation', 'message']
              configDate.value = {
                enableTime: true,
                dateFormat: 'd/m/Y H:i'
              }
              break

            default:
              fieldsVisibility.value = ['to', 'cc', 'cci', 'subject', 'model', 'tiers', 'contrat', 'sinistre', 'devis', 'reclamation', 'message']
              configDate.value = { dateFormat: 'd/m/Y' }
              break
          }
          emailToOptions.value = val === 'Message' ? _.emailsContacts : _.emailsContacts.filter(item => item.type_personne === 'Utilisateur')
          // fieldsVisibility.value = arrayFieldsVisible
        },
        { immediate: true },
        { deep: true }
    )
    watch(
        () => _.emailsContacts,
      val => {
          
        emailToOptions.value = _.typeEvenement === 'Message' ? val.filter(item => item.email !== null) : val.filter(item => item.type_personne === 'Utilisateur')

        EmailAssureurs.forEach(element => {
          emailToOptions.value.unshift(element)
        })

        emailToOptions.value.unshift({
            email: 'Mes collaborateurs',
            name: 'Mes collaborateurs',
            avatar: require('@/assets/images/icons/people-fill.svg'),
            id_client: null,
            user_id: null,
            type_client: null,
            courtier_user_id: null,
            categorie_client: 'Collaborateur',
            type_personne: 'Groupe'
          })

         
        
          console.log(EmailAssureurs)
        
          tiersOptions.value = val.filter(item => item.type_personne === 'Client')

          composeData.value.contrat_id = null
          composeData.value.sinistre_id = null
          composeData.value.devis_id = null
          composeData.value.reclamation_id = null

        },
        { immediate: true },
        { deep: true }
    )
    watch(
        () => modelsSelected.value,
        val => {
          if(_.emailViewData != null){
            if(!val.id){
              Vue.set(composeData.value, 'message','<div><br><br><br><br><blockquote>'+ _.emailViewData.message+'</blockquote></div>')
              if(_.typeAction == 'transfer'){
                Vue.set(composeData.value, 'subject','TR: '+ _.emailViewData.subject)
                let attachments = []
                _.emailViewData.attachments.forEach(item => {
                  attachments.push({
                    client_id: null,
                    client_type: null,
                    document_file_name: item.fileName,
                    document_id: item.documentId,
                    document_name: item.fileNameCustom ? item.fileNameCustom : item.fileName,
                    document_path: item.directory,
                    document_taille: item.size,
                    emplacement_document: item.emplacement_document
                  })
                })

                Vue.set(composeData.value, 'attached', attachments)
              }
              else{
                Vue.set(composeData.value, 'subject','RE: '+ _.emailViewData.subject)
              }
            }
            else {
              Vue.set(composeData.value, 'message','<div>' + val.corps_mail + '</div>' + '<div><br><br><blockquote>' + _.emailViewData.message + '</blockquote></div>')
              if(_.typeAction == 'transfer'){
                Vue.set(composeData.value, 'subject','TR: '+ _.emailViewData.subject)
                let attachments = []
                _.emailViewData.attachments.forEach(item => {
                  attachments.push({
                    client_id: null,
                    client_type: null,
                    document_file_name: item.fileName,
                    document_id: item.documentId,
                    document_name: item.fileNameCustom ? item.fileNameCustom : item.fileName,
                    document_path: item.directory,
                    document_taille: item.size,
                    emplacement_document: item.emplacement_document
                  })
                })

                Vue.set(composeData.value, 'attached', attachments)
              }
              else{
                Vue.set(composeData.value, 'subject','RE: '+ _.emailViewData.subject)
              }
            }
          }
          else{
            Vue.set(composeData.value, 'message', val.corps_mail)
            Vue.set(composeData.value, 'subject', val.objet)
          }
        },
        { immediate: true },
        { deep: true }
    )
    watch(
        () => _.typeAction,
        val => {
          if(_.emailViewData != null){
            if(_.typeAction == 'transfer'){
              Vue.set(composeData.value, 'subject','TR: '+ _.emailViewData.subject)
                let attachments = []
                _.emailViewData.attachments.forEach(item => {
                  attachments.push({
                    client_id: null,
                    client_type: null,
                    document_file_name: item.fileName,
                    document_id: item.documentId,
                    document_name: item.fileNameCustom ? item.fileNameCustom : item.fileName,
                    document_path: item.directory,
                    document_taille: item.size,
                    emplacement_document: item.emplacement_document
                  })
                })

                Vue.set(composeData.value, 'attached', attachments)
                
              }
              else{
                Vue.set(composeData.value, 'subject','RE: '+ _.emailViewData.subject)
              }
            Vue.set(composeData.value, 'message','<br><br><br><br><blockquote>'+ _.emailViewData.message+'</blockquote>')
          }
        },
        { immediate: true },
        { deep: true }
    )



    return {
      composeData,
      modelsOptions,
      configDate,
      editorOption,
      emailToOptions,
      showCcField,
      showCciField,
      optionsEvenement,
      optionsTypeAction,
      textEvenementSelected,
      tiersOptions,
      base64File,
      modelsSelected,

      optionsContrat,
      optionsSinistre,
      optionsDevis,
      optionsReclamation,

      optionsContratShow,
      optionsSinistreShow,
      optionsDevisShow,
      optionsReclamationShow,
      colors,
      showLoading,

      fieldsVisibility,
      // documentsSelected,
      // documentsSelectedFile,
      modelsSelectedMethod,
      // showBccField,

      // Validate form
      emailComposeRules,
      checkDestinataire,
      messageErreur,

      // Email actions
      infoText,
      sendEmail,
      fetchContratSinistreDevisReclamationByTier,
      filterOptionsFormSelect,
      discardEmail,
      detachOrDeleteFile,
      showToast
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }

    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
<style>
.align-left {
  text-align: left !important;
}
</style>
