<template>
  <b-modal id="modal-upload-from-pc" ref="modal-upload-from-pc" cancel-variant="outline-secondary" cancel-title="Tout effacer" :ok-disabled="items.length <= 0" ok-title="Attacher" centered size="xl" title="Parcourir cet ordinateur" @cancel.prevent="removeAllItems" @ok.prevent="attachDataToEmailCompose">
    <validation-observer ref="uploadRules">
      <b-overlay :show="showLoading" no-wrap />
      <div>
        <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeateAgain">
          <!-- Row Loop -->
          <b-row v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row" class="w-100 align-items-center">
            <!-- Famille -->
            <b-col lg="2" md="6">
              <b-form-group label="famille" label-for="h-famille">
                <validation-provider #default="{ errors }" name="famille" rules="required">
                  <b-form-select id="h-famille" v-model="item.Famille" :options="optionsFamille" size="sm" :state="errors.length > 0 ? false : null" @change="changeListoptionType(item.Famille, index)" />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Type -->
            <b-col lg="2" md="6">
              <b-form-group label="type" label-for="h-type">
                <validation-provider #default="{ errors }" name="type" rules="required">
                  <b-form-select id="h-type" v-model="item.idType" :options="item.listType" size="sm" :state="errors.length > 0 ? false : null" />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Nom -->
            <b-col lg="2" md="12">
              <b-form-group label="nom" label-for="h-nom">
                <validation-provider #default="{ errors }" name="nom" rules="required">
                  <b-form-input id="h-nom" v-model="item.nom" type="text" placeholder="Nom" size="sm" :state="errors.length > 0 ? false : null" />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Description -->
            <b-col lg="3" md="12">
              <b-form-group label="description" label-for="h-description">
                <b-form-input id="h-description" v-model="item.description" type="text" placeholder="Description" size="sm" />
              </b-form-group>
            </b-col>

            <!-- Fichier -->
            <b-col lg="2" md="12">
              <b-form-group label="fichier" label-for="h-fichier">
                <validation-provider #default="{ errors }" name="fichier" rules="required">
                  <b-form-file :id="'h-fichier-' + index" v-model="item.file" placeholder="Choisissez un fichier ou déposez-le ici ..." drop-placeholder="déposer le fichier ici..." size="sm" :state="errors.length > 0 ? false : null" />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Buttons Actions -->
            <b-col lg="1" md="12">
              <b-form-group label="Actions" label-for="actions">
                <b-button-group size="sm">
                  <!-- <b-button v-ripple.400="'rgba(0, 207, 232, 0.15)'" variant="outline-primary">
                    <feather-icon icon="SearchIcon" />
                  </b-button> -->
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-primary">
                    <feather-icon icon="XIcon" @click="removeItem(index)" />
                  </b-button>
                  <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary"> <feather-icon icon="RefreshCwIcon" /> </b-button> -->
                </b-button-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>
        </b-form>
      </div>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Nouveau document</span>
      </b-button>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BModal, VBModal, BRow, BCol, BFormGroup, BFormInput, BOverlay, BForm, BButton, BFormSelect, BButtonGroup, BFormFile } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
// eslint-disable-next-line import/named
import documentService from '@/shared/services/documentService'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver,
    // VBT
    BModal,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BForm,
    BButton,
    BFormSelect,
    BButtonGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BFormFile
  },
  mixins: [heightTransition],
  data() {
    return {
      required
    }
  },
  methods: {
    hideModal() {
      this.$refs['modal-upload-from-pc'].hide()
    },
  },
  // mounted() {
  //   console.log(this.documentService.fetch_document_nature_with_types())
  // },
  setup(_, { emit }) {
    const items = ref([])
    const DataFile = ref([])
    const uploadRules = ref(null)
    const optionsFamille = ref([{ value: { id: null, typeDocument: [] }, text: 'Famille', disabled: true }])
    const selectedFamille = ref({ id: null, typeDocument: [] })
    const optionsType = ref([{ value: null, text: 'Type', disabled: true }])
    DataFile.value.selectedType = null
    const nextTodoId = ref(2)

    const form = ref(null)
    const row = ref([])

    const showLoading = ref(false)
    const fetchDocumentNatureWithTypes = documentService()

    fetchDocumentNatureWithTypes
      .then(success => {
        optionsFamille.value = []
        optionsFamille.value.push({ value: { id: null, typeDocument: [] }, text: 'Famille', disabled: true })
        success.data.forEach(el => {
          optionsFamille.value.push({
            value: { id: el.id, typeDocument: el.documentype },
            text: el.libelle
          })
        })
      })
      .catch(errors => {
        console.error(errors)
        optionsFamille.value = []
        optionsFamille.value.push({ value: null, text: 'Famille' })
      })

    // Actions

    const attachDataToEmailCompose = () => {
      uploadRules.value.validate().then(success => {
        if (success) {
          emit('documents-Selected-file', items.value)
          items.value = []
        }
      })
    }

    const repeateAgain = () => {
      items.value.push({
        idFamille: null,
        listFamille: [],
        listType: [],
        idType: null,
        nom: null,
        file: null,
        description: null
      })
    }
    const removeItem = index => {
      items.value.splice(index, 1)
    }
    const removeAllItems = () => {
      items.value = []
    }

    const changeListoptionType = (famille, index) => {
      items.value[index].idFamille = famille.id
      items.value[index].idType = null
      items.value[index].listType = []
      famille.typeDocument.forEach(el => {
        items.value[index].listType.push({
          value: el.id,
          text: el.type
        })
      })
    }
    // Watchers
    // watch(
    //   () => selectedFamille.value,
    //   val => {
    //     DataFile.value.selectedFamille = val.id
    //     optionsType.value = []
    //     optionsType.value.push({ value: null, text: 'Type', disabled: true })
    //     val.typeDocument.forEach(el => {
    //       optionsType.value.push({
    //         value: el.id,
    //         text: el.type
    //       })
    //     })
    //     DataFile.value.selectedType = null
    //   },
    //   { deep: true }
    // )
    return {
      // UI
      showLoading,
      items,
      nextTodoId,
      selectedFamille,
      // selectedType,
      fetchDocumentNatureWithTypes,
      DataFile,

      // options
      optionsFamille,
      optionsType,

      // refs
      form,
      row,
      // Validate form
      uploadRules,

      // Actions
      attachDataToEmailCompose,
      repeateAgain,
      removeItem,
      removeAllItems,
      changeListoptionType
    }
  }
}
</script>
<style lang="scss" scoped>
.repeater-form {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.35s height;
}
</style>
