var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-upload-from-pc",attrs:{"id":"modal-upload-from-pc","cancel-variant":"outline-secondary","cancel-title":"Tout effacer","ok-disabled":_vm.items.length <= 0,"ok-title":"Attacher","centered":"","size":"xl","title":"Parcourir cet ordinateur"},on:{"cancel":function($event){$event.preventDefault();return _vm.removeAllItems.apply(null, arguments)},"ok":function($event){$event.preventDefault();return _vm.attachDataToEmailCompose.apply(null, arguments)}}},[_c('validation-observer',{ref:"uploadRules"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"no-wrap":""}}),_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain.apply(null, arguments)}}},_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,staticClass:"w-100 align-items-center",attrs:{"id":item.id}},[_c('b-col',{attrs:{"lg":"2","md":"6"}},[_c('b-form-group',{attrs:{"label":"famille","label-for":"h-famille"}},[_c('validation-provider',{attrs:{"name":"famille","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-famille","options":_vm.optionsFamille,"size":"sm","state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.changeListoptionType(item.Famille, index)}},model:{value:(item.Famille),callback:function ($$v) {_vm.$set(item, "Famille", $$v)},expression:"item.Famille"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"6"}},[_c('b-form-group',{attrs:{"label":"type","label-for":"h-type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-type","options":item.listType,"size":"sm","state":errors.length > 0 ? false : null},model:{value:(item.idType),callback:function ($$v) {_vm.$set(item, "idType", $$v)},expression:"item.idType"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"12"}},[_c('b-form-group',{attrs:{"label":"nom","label-for":"h-nom"}},[_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nom","type":"text","placeholder":"Nom","size":"sm","state":errors.length > 0 ? false : null},model:{value:(item.nom),callback:function ($$v) {_vm.$set(item, "nom", $$v)},expression:"item.nom"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"12"}},[_c('b-form-group',{attrs:{"label":"description","label-for":"h-description"}},[_c('b-form-input',{attrs:{"id":"h-description","type":"text","placeholder":"Description","size":"sm"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"12"}},[_c('b-form-group',{attrs:{"label":"fichier","label-for":"h-fichier"}},[_c('validation-provider',{attrs:{"name":"fichier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":'h-fichier-' + index,"placeholder":"Choisissez un fichier ou déposez-le ici ...","drop-placeholder":"déposer le fichier ici...","size":"sm","state":errors.length > 0 ? false : null},model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"1","md":"12"}},[_c('b-form-group',{attrs:{"label":"Actions","label-for":"actions"}},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"}},[_c('feather-icon',{attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Nouveau document")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }