<template>
  <div ref="upload-from-ged">
    <b-modal id="modal-upload-from-ged" ref="modal-upload-from-ged" cancel-variant="outline-secondary" cancel-title="Fermer" ok-title="Attacher" centered size="xl" title="Parcourir la GED" @ok.prevent="attachDataToEmailCompose">
      <b-row>
        <b-col lg="2">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Document: " label-for="h-document">
                <b-form-select v-model="documentSearch" :options="optionsDocumentSearch" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="10">
          <b-overlay :show="showLoading" no-wrap />
          <vue-good-table
            :columns="columns"
            @on-selected-rows-change="selectionChanged"
            :rows="items"
            :search-options="{
              enabled: true,
              placeholder: 'Chercher'
            }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item @click="displayDocument(props.row.id, props.row.Name, props.row.NomDuDocument)">
                      <feather-icon icon="SearchIcon" class="mr-50" />
                      <span>Visualiser</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <b-row class="mt-2 align-items-center">
                <b-col md="6" lg="5" xxl="3">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Affichage 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> de {{ props.total }} entrées </span>
                  </div>
                </b-col>
            </b-row>
              <div class="d-flex justify-content-between flex-wrap">
                <div>
                  <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="value => props.pageChanged({ currentPage: value })">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
            <div class="text-center align-middle" slot="emptystate">
              <span>Aucune donn&eacute;e disponible dans le tableau</span>
            </div>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-display-document-ged" ref="modal-display-document-ged" :title="document.name" cancel-title="Fermer" ok-title="Télécharger" cancel-variant="outline-secondary" @ok="downloadDocument(document.base64, document.nameToDownload)">
      <form ref="form">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :src="document.base64" fluid :alt="document.name" />
      </form>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { BModal, VBModal, BRow, BCol, BPagination, BFormSelect, BFormGroup, BFormInput, BOverlay, BDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  components: {
    // VBT
    BModal,
    BImg,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,

    // VGT
    VueGoodTable,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue
  },
  props: {
    composeTier: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      pageLength: 6,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      }
    }
  },
  methods: {
    hideModal() {
      this.$refs['modal-upload-from-ged'].hide()
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    // eslint-disable-next-line consistent-return
    displayDocument(id, name, NomDuDocument) {
      if (!id) {
        this.messageToast("Aucun document trouvé pour l'affichage ou le téléchargement.", 'Information', 'warning')
        return 0
      }
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (res.data.data.isDownload) {
              this.downloadDocument(res.data.data.base64, name)
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document-ged'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
              this.downloadDocument(res.data.data.base64, name)
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    }
  },
  setup(_, { emit }) {
    const items = ref([])
    const itemsSelected = ref([])
    const dir = ref(false)
    const showLoading = ref(false)
    const columns = ref([
      { label: 'Nom du client', field: 'clientName', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      { label: 'Famille de document', field: 'FamilleDeDocument', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      { label: 'Type de document', field: 'TypeDeDocument', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      { label: 'Nom du document', field: 'NomDuDocument', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      { label: 'Créé le', field: 'CreeLe', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      { label: 'Taille', field: 'Taille', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      { label: 'Actions', field: 'actions', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
    ])
    const optionsDocumentSearch = ref(['Clients', 'Courtier'])
    const documentSearch = ref('Clients')
    // Actions
    const fetchDocumentClientByTier = tier => {
      showLoading.value = true
      store
        .dispatch('messageries/fetchDocumentClientByTier', { tier })
        .then(response => {
          items.value = response.data
        })
        .finally(() => {
          documentSearch.value = 'Clients'
          columns.value = [
            { label: 'Nom du client', field: 'clientName', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
            { label: 'Famille de document', field: 'FamilleDeDocument', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
            { label: 'Type de document', field: 'TypeDeDocument', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
            { label: 'Nom du document', field: 'NomDuDocument', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
            { label: 'Créé le', field: 'CreeLe', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
            { label: 'Taille', field: 'Taille', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
            { label: 'Actions', field: 'actions', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
          ]
          showLoading.value = false
        })
    }
    const selectionChanged = val => {
      itemsSelected.value = val
    }
    const attachDataToEmailCompose = () => {
      emit('documents-Selected', itemsSelected.value, documentSearch.value)
    }
    const fetchDocumentCourtier = () => {
      showLoading.value = true
      store
        .dispatch('messageries/fetchDocumentCourtier')
        .then(response => {
          items.value = response.data.data
        })
        .finally(() => {
          columns.value = [
            {
              label: 'Famille de document',
              field: 'FamilleDeDocument',
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-1 align-middle h6'
            },
            {
              label: 'Type de document',
              field: 'TypeDeDocument',
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-1 align-middle h6'
            },
            {
              label: 'Nom du document',
              field: 'NomDuDocument',
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-1 align-middle h6'
            },
            {
              label: 'Créé le',
              field: 'CreeLe',
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-1 align-middle h6'
            },
            {
              label: 'Taille',
              field: 'Taille',
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-1 align-middle h6'
            },
            {
              label: 'Actions',
              field: 'actions',
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-1 align-middle h6'
            }
          ]
          documentSearch.value = 'Courtier'
          showLoading.value = false
        })
    }

    // Watchers
    watch(
      () => documentSearch.value,
      val => {
        if (val === 'Clients') {
          fetchDocumentClientByTier(_.composeTier)
        } else {
          fetchDocumentCourtier()
        }
      },
      { deep: true }
    )
    watch(
      () => _.composeTier,
      val => {
        fetchDocumentClientByTier(val)
      },
      { immediate: true },
      { deep: true }
    )

    return {
      // UI
      items,
      dir,
      columns,
      showLoading,
      optionsDocumentSearch,
      documentSearch,
      itemsSelected,

      // Actions
      fetchDocumentClientByTier,
      fetchDocumentCourtier,
      selectionChanged,
      attachDataToEmailCompose
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/base/pages/app-email.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
