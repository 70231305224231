<template>
  <div class="email-app-details">
    <!-- Email Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'" size="20" class="align-bottom" @click="$emit('close-email-view')" />
        </span>
        <h4 class="email-subject mb-0">
          {{ emailViewData.subject }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">
        <!-- Mark Starred -->
        <!-- <feather-icon icon="StarIcon" size="17" class="cursor-pointer" :class="{ 'text-warning fill-current': emailViewData.isStarred }" @click="$emit('toggle-email-starred')" /> -->

        <!-- Move email to folder -->
        <!-- <b-dropdown variant="link" no-caret toggle-class="p-0" right class="ml-75">
          <template #button-content>
            <feather-icon icon="FolderIcon" size="17" />
          </template>

          <b-dropdown-item @click="$emit('move-email-to-folder', 'draft')">
            <feather-icon icon="Edit2Icon" />
            <span class="align-middle ml-50">Draft</span>
          </b-dropdown-item>

          <b-dropdown-item @click="$emit('move-email-to-folder', 'spam')">
            <feather-icon icon="InfoIcon" />
            <span class="align-middle ml-50">Spam</span>
          </b-dropdown-item>

          <b-dropdown-item v-show="$route.params.folder !== 'trash'" @click="$emit('move-email-to-folder', 'trash')">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Trash</span>
          </b-dropdown-item>
        </b-dropdown> -->

        <!-- Update Label -->
        <b-dropdown variant="link" no-caret toggle-class="p-0" class="ml-75" right>
          <template #button-content>
            <feather-icon icon="TagIcon" size="17" class="align-middle text-body" />
          </template>
          <b-dropdown-item @click="$emit('update-email-label', 'Reseaux')">
            <span class="mr-50 bullet bullet-warning bullet-sm" />
            <span>Réseaux</span>
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('update-email-label', 'Assureurs')">
            <span class="mr-50 bullet bullet-info bullet-sm" />
            <span>Assureurs</span>
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('update-email-label', 'Clients')">
            <span class="mr-50 bullet bullet-secondary bullet-sm" />
            <span>Clients</span>
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('update-email-label', 'Comptabilité')">
            <span class="mr-50 bullet bullet-danger bullet-sm" />
            <span>Comptabilité</span>
          </b-dropdown-item>
        </b-dropdown>

        <!-- Mark Un-Read -->
        <!-- <feather-icon icon="MailIcon" class="ml-75 cursor-pointer" size="17" @click="$emit('mark-email-unread')" /> -->
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <feather-icon icon="MailIcon" size="17" class="text-body cursor-pointer ml-1" />
          </template>

          <b-dropdown-item @click="$emit('mark-email-unread', false)">
            <span class="align-middle ml-50">Marquer comme non lu</span>
          </b-dropdown-item>

          <!-- <b-dropdown-item @click="$emit('mark-email-unread', true)">
            <span class="align-middle ml-50">Marquer comme lu</span>
          </b-dropdown-item> -->
        </b-dropdown>

        <!-- create Reminder -->
        <!-- <feather-icon icon="ClockIcon" class="ml-75" size="17" v-b-modal.modal-create-reminder /> -->

        <!-- Affect a client -->
        <feather-icon icon="UserCheckIcon" class="ml-75" size="17" v-b-modal.modal-affect-tier />

        <!-- Move to Trash -->
        <feather-icon v-show="$route.params.folder !== 'trash'" icon="TrashIcon" class="ml-75" size="17" @click="$emit('remove-email')" />

        <!-- Show Previous Mail -->
        <!-- <feather-icon :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'" size="17" class="ml-75 cursor-pointer" :class="{ 'text-muted pointer-events-none': !opendedEmailMeta.hasPreviousEmail }" @click="$emit('change-opened-email', 'previous', true)" /> -->

        <!-- Show Next Mail -->
        <!-- <feather-icon :icon="$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon'" size="17" class="ml-75 cursor-pointer" :class="{ 'text-muted pointer-events-none': !opendedEmailMeta.hasNextEmail }" @click="$emit('change-opened-email', 'next', true)" /> -->
      </div>
    </div>

    <!-- <validation-observer ref="reminderRules">
      <b-modal id="modal-create-reminder" centered title="Créer un rappel" ok-only ok-title="Rappelle moi" @ok.prevent="validationFormReminder()" cancel-variant="outline-secondary" cancel-title="Fermer">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Type de rappel" label-for="h-type-rappel" label-cols-md="4">
                <validation-provider #default="{ errors }" name="type de rappel" rules="required">
                  <b-form-select id="h-type-rappel" v-model="selectedReminderType" :options="optionsRappel" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Valeur de rappel" label-for="h-valeur-rappel" label-cols-md="4">
                <validation-provider #default="{ errors }" name="valeur de rappel" rules="required">
                  <b-form-input id="h-valeur-rappel" v-model="selectedReminderValue" type="number" placeholder="Valeur de rappel" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer> -->

    <validation-observer ref="tierRules">
      <b-modal id="modal-affect-tier" size="lg" centered title="Affecter à un Client/Prospect" ok-only ok-title="Affecter" @ok.prevent="validationFormTier()" cancel-variant="outline-secondary" cancel-title="Fermer">
        <b-form>
          <b-row>
            <b-col>
              <b-form-group label="Sélectionner un client/prospect" label-for="h-tier" label-cols-lg="12">
                <validation-provider #default="{ errors }" name="tier" rules="required">
                  <v-select v-model="selectedTier" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" class="flex-grow-1" :close-on-select="true" :options="tiersOptions" input-id="tier" :state="errors.length > 0 ? false : null">
                    <template #option="{ avatar, name, type_client, categorie_client }">
                      <b-avatar size="sm" :src="avatar" />
                      <span class="ml-50"> {{ name }}</span>
                      <b-badge class="mr-2 float-right" :variant="typeClientVariant(categorie_client)"> {{ capitalizeFirstLetter(categorie_client) }} </b-badge>
                    </template>

                    <template #selected-option="{ avatar, name }">
                      <b-avatar size="sm" class="border border-white" :src="avatar" />
                      <span class="ml-50"> {{ name }}</span>
                    </template>
                    <div slot="no-options">Aucune Client/Prospect disponible.</div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>

    <!-- Email Details -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="email-scroll-area scroll-area">
      <!-- Label Row -->
      <b-row>
        <b-col cols="12">
          <div class="email-label">
<!--            <b-badge v-for="label in emailViewData.labels" :key="label" pill class="text-capitalize mr-50" :variant="`light-${resolveLabelColor(label)}`">-->
<!--              {{ label }}-->
<!--            </b-badge>-->
            <b-badge pill class="text-capitalize mr-50" :variant="`light-${resolveLabelColor(emailViewData.emailLabel)}`">
              {{ resolveLabelText(emailViewData.emailLabel) }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <!-- Action: Show Earlier Message -->
      <b-row v-if="!showWholeThread && emailViewData.replies && emailViewData.replies.length" class="mb-1">
        <b-col cols="12">
          <b-link class="font-weight-bold" @click="showWholeThread = true"> Voir {{ emailViewData.replies.length }} message antérieur{{ emailViewData.replies.length > 1 ? 's' : '' }} </b-link>
        </b-col>
      </b-row>

      <!-- Earlier Email Messages -->
      <template v-if="showWholeThread">
        <b-row v-for="threadMail in emailViewData.replies.slice().reverse()" :key="threadMail.id">
          <b-col cols="12">
            <email-message-card :message="threadMail" @reply-on-an-email-from-card="replyOnAnEmail" />
          </b-col>
        </b-row>
      </template>

      <!-- Email Thread -->
      <b-row>
        <b-col cols="12">
          <email-message-card :message="emailViewData" @reply-on-an-email-from-card="replyOnAnEmail" />
        </b-col>
      </b-row>

      <!-- Email Actions: Reply or Forward -->
      <!-- <b-row>
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Cliquez ici pour
                <b-link>Répondre</b-link>
                ou
                <b-link>Transférer</b-link>
              </h5>
            </div>
          </b-card>
        </b-col>
      </b-row> -->
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BRow, BCol, BBadge, BCard, BLink, BFormSelect, VBModal, BFormInput, BFormGroup, BForm, BAvatar } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import useEmail from './useEmail'
import EmailMessageCard from './EmailMessageCard.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // BSV
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    BFormSelect,
    BForm,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    BFormInput,
    BFormGroup,

    // 3rd Party
    VuePerfectScrollbar,
    vSelect,

    // SFC
    EmailMessageCard
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  props: {
    emailViewData: {
      type: Object,
      required: true
    },
    opendedEmailMeta: {
      type: Object,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    emailsContacts: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      typeClientOptions: [
        {
          1: 'PARTICULIER',
          2: 'PROFESSIONNEL',
          3: 'ENTREPRISE'
        },
        {
          1: 'light-danger',
          2: 'light-warning',
          3: 'light-success'
        }
      ],
      selectedReminderType: '',
      selectedReminderValue: '',
      selectedTier: null,
      required
      // window: {
      //   width: 0,
      //   height: 0
      // }
    }
  },
  // created() {
  //   window.addEventListener('resize', this.handleResize)
  //   this.handleResize()
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.handleResize)
  // },

  methods: {
    typeClientVariant(categorie) {
      try {
        const typeClientColor = {
          /* eslint-disable key-spacing */
          PARTICULIER: 'light-danger',
          PROFESSIONNEL: 'light-warning',
          ENTREPRISE: 'light-success',
          /* eslint-enable key-spacing */
        }
        if (categorie) {
          return typeClientColor[categorie]
        }
        return null
      } catch (e) {
        return null
      }
    },
    replyOnAnEmail(typeAction) {
      this.$emit('reply-on-an-email-from-view', typeAction)
    },
    validationFormTier() {
      this.$refs.tierRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$emit('affect-client-action', this.selectedTier, this.emailViewData.id_evenement)
          this.$emit('update-email-label', 'Clients')
          this.$bvModal.hide('modal-affect-tier')
          this.selectedTier = null
        }
      })
    },
    validationFormReminder() {
      this.$refs.reminderRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$emit('remind-me-action', this.selectedReminderType, this.selectedReminderValue, this.emailViewData.id)
          this.$bvModal.hide('modal-create-reminder')
          this.selectedReminderType = ''
          this.selectedReminderValue = ''
        }
      })
    }
    // handleResize() {
    //   this.window.width = window.innerWidth
    //   this.window.height = window.innerHeight
    // }
  },
  setup(props) {
    const { resolveLabelColor } = useEmail()
    const { resolveLabelText } = useEmail()
    const optionsRappel = ref([
      { value: 'minute', text: 'Minute' },
      { value: 'heure', text: 'Heure' },
      { value: 'jour', text: 'Jour' }
    ])
    const tiersOptions = ref([])

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150
    }

    const showWholeThread = ref(false)

    watch(
      () => props.emailViewData.id,
      () => {
        showWholeThread.value = false
      }
    )
    watch(
      () => props.emailsContacts,
      val => {
        tiersOptions.value = val.filter(item => item.type_personne === 'Client')
      },
      { immediate: true },
      { deep: true }
    )
    return {
      // UI
      perfectScrollbarSettings,
      showWholeThread,

      // value constant
      optionsRappel,
      tiersOptions,

      // useEmail
      resolveLabelColor,
      resolveLabelText,
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
